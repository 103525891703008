import { Dimension } from "store/types/resizeSettings";
import { EnlargeState } from "../../types";

interface IPayload {
    payload: Dimension;
}

export const setDimensionType = (
    state: EnlargeState,
    { payload: dimension }: IPayload
) => {
    state.resizeSettings.dimensionType = dimension;
};
