import { ResizeBackgroundImportState } from "../types";

interface Payload {
    payload: boolean;
}

export const setImportComplete = (state: ResizeBackgroundImportState, { payload: value }: Payload) => {
    state.importComplete = value;
    state.isImporting = !value;
};
