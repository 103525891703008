import { initialState } from "../initialState";
import { ResizeEditorState } from "../types";

export const resetSettings = (state: ResizeEditorState) => {
    state.crop = initialState.crop;
    state.currentType = initialState.currentType;
    state.editModal = initialState.editModal;
    state.flip = initialState.flip;
    state.history = initialState.history;
    state.resize = initialState.resize;
    state.rotate = initialState.rotate;
    state.operations = initialState.operations;
};
