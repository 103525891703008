import { DEFAULT_MEME_TEXT } from "../../data/defaultMemeText";
import { MemeGeneratorState, TextBoxStates } from "../../types";
import { getHighestZIndexElement } from "../../utils/getHighestZIndexElement";
import { getTextBoxDefaultStyles } from "../../utils/getTextBoxDefaultStyles";

interface Payload {
    payload: {
        id: string;
    };
}

export const addTextBox = (state: MemeGeneratorState, { payload: { id } }: Payload) => {
    if (!state.meme) throw new Error("Meme not found");
    const width = state.meme.settings.image.width * 0.8;
    const left = (state.meme.settings.image.width - width) / 2;
    const height = 100;
    state.meme.activeTextBoxId = id;
    state.meme.textBoxes = [
        ...state.meme.textBoxes,
        {
            id,
            width,
            height,
            left,
            top: state.meme.settings.image.height / 2 - height / 2,
            rotation: 0,
            value: DEFAULT_MEME_TEXT,
            innerHTML: DEFAULT_MEME_TEXT,
            ...getTextBoxDefaultStyles({
                forceCaps: false,
                textAlign: "center",
                verticalAlign: "start",
                fontColor: "#000",
                font: state.meme.textBoxes[0]?.fontFamily || "Impact",
            }),
            state: TextBoxStates.INSERT,
            showBorder: true,
            showHandlers: true,
            zIndex: (getHighestZIndexElement(state)?.zIndex || 0) + 1,
            forceCaps: typeof state.meme.textBoxes[0] === "undefined" ? false : state.meme.textBoxes[0].forceCaps,
        },
    ];
};
