import { Task } from "@freeconvert/freeconvert-node/dist/types";
import { formatBytes } from "client/utils/formatBytes";
import { BulkResizeImage, BulkResizeState } from "../../types";

interface Payload {
    payload: {
        imageId: string;
        importing: BulkResizeImage["importing"];
        importTask: Task;
    };
}

export const setImporting = (state: BulkResizeState, { payload: { imageId, importing, importTask } }: Payload) => {
    state.images = state.images.map((_image) => {
        if (_image.id !== imageId) return _image;

        return {
            ..._image,
            original: {
                ..._image.original,
                size: importTask.result?.size
                    ? formatBytes(importTask.result.size)
                    : _image.original.size.includes("NaN")
                    ? "0 KB"
                    : _image.original.size,
            },
            importing,
            importTask,
        };
    });
};
