import { Job } from "@freeconvert/freeconvert-node/dist/types";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { IPostReturn, post } from "client/services/Backend/post";
import { ResizeJobPayload } from "store/features/resizeEditor/extraReducers/createResizeJob";
import { BulkResizeState } from "../types";

export interface BulkResizeJobPaylaod extends Omit<ResizeJobPayload, "importTask" | "crop"> {
    importTaskIds: string[];
}

export const createBulkResizeJob = createAsyncThunk(
    "bulkResize/createBulkResizeJob",
    async ({ importTaskIds, resize }: BulkResizeJobPaylaod): Promise<IPostReturn<Job>> => {
        let bulkResizeData: any = {};
        let resizeOptions: any = {
            max_file_size: resize.options.maxFileSize,
            background_color: resize.options.background,
            image_dpi: resize.options.dpi,
            "auto-orient": true,
            strip: true,
        };

        if (resize.resize_as === "percentage") {
            resizeOptions = {
                ...resizeOptions,
                image_resize_percentage: resize.percentage,
            };
        } else {
            resizeOptions = {
                ...resizeOptions,
                image_custom_width: resize.dimensions?.width,
                image_custom_height: resize.dimensions?.height,
            };
        }

        if (resize.outputFormat === "png") {
            resizeOptions = {
                ...resizeOptions,
                png_convert_quality: 100,
                png_compression_level: "lossy",
            };
        }

        if (resize.resize_as === "percentage") {
            bulkResizeData["percentage"] = resize.percentage;
        } else if (resize.resize_as === "dimensions") {
            bulkResizeData["dimensions"] = resize.dimensions;
        }

        bulkResizeData = {
            ...bulkResizeData,
            operation: "convert",
            input: importTaskIds,
            input_format: resize.inputFormat?.toLowerCase(),
            output_format: resize.outputFormat?.toLowerCase(),
            resize_as: resize.resize_as,
            options: resizeOptions,
        };

        const result = await post<Job>("/api/jobs", {
            tag: "bulk-resize",
            tasks: {
                bulkResize: bulkResizeData,
            },
        });

        if (result.error) {
            throw {
                error: result.error,
            };
        }

        return result;
    },
);

export const handleCreateBulkResizeJob = (builder: ActionReducerMapBuilder<BulkResizeState>) => {};
