import { EnlargeState } from "../../types";

interface IPayload {
    payload: boolean;
}

export const setIsLocked = (
    state: EnlargeState,
    { payload: isLocked }: IPayload
) => {
    state.resizeSettings.isLocked = isLocked;
};
