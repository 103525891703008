import { BulkResizeImage, BulkResizeState } from "../../types";

interface Payload {
    payload: BulkResizeImage[];
}

export const setImages = (state: BulkResizeState, { payload: _images }: Payload) => {
    state.images = _images;
    state.immutableImages = [...state.images];
    state.fromDownloadPage = true;
};
