import { convertFromPixels } from "@/utils/pixels/convertFromPixels";
import { roundFloat } from "client/utils/roundFloat";
import { BulkResizeState } from "../../types";

interface IPayload {
    payload: number;
}

export const setWidth = (state: BulkResizeState, { payload: _width }: IPayload) => {
    let width = _width;
    if (!width || width < 0) {
        width = 0;
    }

    state.resizeSettings.byDimension.width = width;

    state.resizeSettings.byDimension.lastModified = "width";
    state.lastModified = "width";

    if (state.resizeSettings.isLocked) {
        state.resizeSettings.byDimension.height = 0;
    }

    const unit = state.resizeSettings.unit;
    const dpi = state.resizeSettings.dpi;

    state.images = state.images.map((image) => {
        const newWidth = width > 0 ? width : convertFromPixels(image.original.width, unit, +dpi);
        const newHeight = roundFloat((newWidth / image.original.width) * image.original.height);

        return {
            ...image,
            preview: {
                ...image.preview,
                width: newWidth,
                height: state.resizeSettings.isLocked
                    ? state.resizeSettings.unit === "pixels"
                        ? Math.round(newHeight)
                        : newHeight
                    : image.preview.height,
            },
        };
    });
};
