import { MemeGeneratorState } from "../../types";

interface Payload {
    payload: { id: string } & Partial<NonNullable<MemeGeneratorState["meme"]>["icons"][number]["settings"]>;
}

export const setSettings = (state: MemeGeneratorState, { payload: { id, ...rest } }: Payload) => {
    if (!state.meme) throw new Error(`Tried to add icon when meme is ${state.meme}`);
    const icon = state.meme.icons.find((icon) => icon.id === id);
    if (!icon) throw new Error(`Icon with id: ${id} not found`);
    icon.settings = {
        ...icon.settings,
        ...rest,
    };
};
