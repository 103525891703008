import { Dimension } from "store/types/resizeSettings";
import { bulkResizeSlice } from "../../slice";
import { BulkResizeState } from "../../types";

interface IPayload {
    payload: Dimension;
}

export const setDimensionType = (state: BulkResizeState, { payload: dimension }: IPayload) => {
    state.resizeSettings.dimensionType = dimension;
    if (dimension === "percentage") {
        bulkResizeSlice.caseReducers.setPercentage(state, { payload: state.resizeSettings.byPercentage.percentage });
        return;
    }

    if (state.resizeSettings.byDimension.lastModified === "width") {
        bulkResizeSlice.caseReducers.setWidth(state, { payload: state.resizeSettings.byDimension.width });
    } else {
        bulkResizeSlice.caseReducers.setHeight(state, { payload: state.resizeSettings.byDimension.height });
    }
};
