import { ResizeEditorState } from "../../types";

interface IPayload {
    payload: number;
}

export const setHeight = (state: ResizeEditorState, { payload: _height }: IPayload) => {
    if (!state.image) return;
    state.crop.height = _height > 0 ? (_height > state.image.originalHeight ? state.image.originalHeight : _height) : 0;
};
