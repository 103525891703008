import { BulkResizeState } from "../../types";

interface IPayload {
    payload: boolean;
}

export const setIsLocked = (state: BulkResizeState, { payload: isLocked }: IPayload) => {
    state.resizeSettings.isLocked = isLocked;

    if (state.resizeSettings.byDimension.lastModified === "width") {
        state.resizeSettings.byDimension.height = 0;

        state.images = state.images.map((image) => {
            return {
                ...image,
                preview: {
                    ...image.preview,
                    height: state.resizeSettings.isLocked
                        ? (image.preview.width / image.original.width) * image.original.height
                        : image.preview.height,
                },
            };
        });
    } else {
        state.resizeSettings.byDimension.width = 0;

        state.images = state.images.map((image) => {
            return {
                ...image,
                preview: {
                    ...image.preview,
                    width: state.resizeSettings.isLocked
                        ? (image.preview.height / image.original.height) * image.original.width
                        : image.preview.width,
                },
            };
        });
    }
};
