import { ImageUpload } from "store/features/imageUploader/types";
import { ColorPickerState } from "../types";

interface Payload {
    payload: ImageUpload["upload"];
}

export const setUploadData = (state: ColorPickerState, { payload: value }: Payload) => {
    state.upload = value;
};
