import { getImageExtension } from "client/utils/getImageExtension";
import { CompressionSettings, CompressState } from "../../types";
import { getCompressionSettings } from "../../utils/getCompressionSettings";

interface Payload {
    payload: CompressionSettings;
}

export const setAllImagesCompressionSettings = (state: CompressState, { payload: value }: Payload) => {
    state.images = state.images.map(image => {
        image.compressionSettings = getCompressionSettings(getImageExtension(image.file.file), value);
        return image;
    });
    state.compressionSettings = value;
};