import { setColor as resizeSetColor } from "./resize/setColor";
import { setDimensionType as resizeSetDimensionType } from "./resize/setDimensionType";
import { setDPI as resizeSetDPI } from "./resize/setDPI";
import { setEditType as resizeSetEditType } from "./resize/setEditType";
import { setHeight as resizeSetHeight } from "./resize/setHeight";
import { setIsLocked as resizeSetIsLocked } from "./resize/setIsLocked";
import { setPercentage as resizeSetPercentage } from "./resize/setPercentage";
import { setSaveAs as resizeSetSaveAs } from "./resize/setSaveAs";
import { setShouldFillColor as resizeSetShouldFillColor } from "./resize/setShouldFillColor";
import { setTargetFileSize as resizeSetTargetFileSize } from "./resize/setTargetFileSize";
import { setUnit as resizeSetUnit } from "./resize/setUnit";
import { setWidth as resizeSetWidth } from "./resize/setWidth";
import { toggleDimension as resizeToggleDimension } from "./resize/toggleDimension";
import { setOptimizationLevel as resizeSetOptimizationLevel } from "./resize/setOptimizationLevel";
import { setFileUnit as resizeSetFileUnit } from "./resize/setFileUnit";
import { setFillColorType as resizeSetFillColorType } from "./resize/setFillColorType";
import { setHeight as cropSetHeight } from "./crop/setHeight";
import { setWidth as cropSetWidth } from "./crop/setWidth";
import { setRatio as cropSetRatio } from "./crop/setRatio";
import { toggleCropOrientation as cropToggleCropOrientation } from "./crop/toggleCropOrientation";
import { setLeft as cropSetLeft } from "./crop/setLeft";
import { setTop as cropSetTop } from "./crop/setTop";
import { reset as cropReset } from "./crop/reset";
import { setCustomRatioX as cropSetCustomRatioX } from "./crop/setCustomRatioX";
import { setCustomRatioY as cropSetCustomRatioY } from "./crop/setCustomRatioY";
import { setCropBox as cropSetCropBox } from "./crop/setCropBox";
import { handleCropBoxChange as cropHandleCropBoxChange } from "./crop/handleCropBoxChange";
import { toggleHorizontal as flipToggleHorizontal } from "./flip/toggleHorizontal";
import { toggleVertical as flipToggleVertical } from "./flip/toggleVertical";
import { setRotation as rotateSetRotation } from "./rotate/setRotation";
import { setStraighten as rotateSetStraighten } from "./rotate/setStraighten";
import { setInitialData as rotateSetInitialData } from "./rotate/setInitialData";
import { push as historyPush } from "./history/push";
import { pop as historyPop } from "./history/pop";
import { open as modalOpen } from "./modal/open";
import { close as modalClose } from "./modal/close";
import { setImage } from "./image/setImage";
import { reset as flipReset } from "./flip/reset";
import { reset as rotateReset } from "./rotate/reset";
import { setRatio as imageSetRatio } from "./image/setRatio";
import { reset } from "./reset";
import { resetSettings } from "./resetSettings";
import { addOperation } from "./operations/addOperation";
import { deleteAllOperations } from "./operations/deleteAllOperations";

export const reducers = {
    // image
    setImage,
    imageSetRatio,
    // resize
    resizeSetEditType,
    resizeSetDimensionType,
    resizeSetWidth,
    resizeSetHeight,
    resizeSetIsLocked,
    resizeSetDPI,
    resizeSetSaveAs,
    resizeSetTargetFileSize,
    resizeSetUnit,
    resizeSetPercentage,
    resizeSetColor,
    resizeSetShouldFillColor,
    resizeToggleDimension,
    resizeSetOptimizationLevel,
    resizeSetFileUnit,
    resizeSetFillColorType,
    // crop
    cropSetWidth,
    cropSetHeight,
    cropSetRatio,
    cropToggleCropOrientation,
    cropSetLeft,
    cropSetTop,
    cropSetCropBox,
    cropReset,
    cropHandleCropBoxChange,
    cropSetCustomRatioX,
    cropSetCustomRatioY,
    // flip
    flipToggleHorizontal,
    flipToggleVertical,
    flipReset,
    //rotate
    rotateSetRotation,
    rotateSetStraighten,
    rotateReset,
    rotateSetInitialData,
    // history
    historyPush,
    historyPop,
    // modal
    modalOpen,
    modalClose,
    // utils
    reset,
    resetSettings,
    // operations
    addOperation,
    deleteAllOperations,
};
