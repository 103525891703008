import { ImageExtension } from "store/types/imageExtension";
import { ResizeEditorState } from "../../types";

interface IPayload {
    payload: ImageExtension;
}

export const setSaveAs = (state: ResizeEditorState, { payload: extension }: IPayload) => {
    state.resize.exportSettings.saveAs = extension;

    if (extension === ".png" && state.resize.exportSettings.optimizationLevel === "custom") {
        state.resize.exportSettings.optimizationLevel = "best";
    }

    if (extension === ".jpg") {
        state.resize.fillColor.type = "color";
    }
};
