import { ToastsState, Toast } from "../types";

interface Payload {
    payload: {
        toast: Toast;
    };
}

export const addToast = (state: ToastsState, { payload: { toast } }: Payload) => {
    /*
        dont add the toast if batch is not empty
    */
    if (toast.batch) {
        const toastsOfSameBatch = state.toasts.filter((_toast) => _toast.batch === toast.batch);
        if (toastsOfSameBatch.length > 0) return;
    }

    state.toasts = [...state.toasts, { ...toast }];
};
