import { Task } from "@freeconvert/freeconvert-node/dist/types";
import { ResizeBackgroundImportState } from "../types";

interface Payload {
    payload: Task;
}

export const setTask = (state: ResizeBackgroundImportState, { payload: task }: Payload) => {
    if (!state.task) state.task = task;
};
