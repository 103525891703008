import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IPostReturn, post } from "client/services/Backend/post";
import { UploadSettings } from "client/types/HandleFile";
import { Task } from "@freeconvert/freeconvert-node/dist/types";
import { ImageUploaderState, UploadType } from "../types";

export interface CreateImportTaskPayload {
    imageId: string;
    type: UploadType;
    options: UploadSettings[UploadType];
}

export type CreateImportTask = AsyncThunk<IPostReturn<Task>, CreateImportTaskPayload, {}>;

export const createImportTask: CreateImportTask = createAsyncThunk(
    "uploader/createImportTask",
    async ({ type, options }: CreateImportTaskPayload): Promise<IPostReturn<Task>> => {
        if (type === "device") {
            const result = await post<Task>("/api/tasks/import/device", options);

            if (result.error) {
                throw { error: result.error };
            }
            return result;
        }

        if (type === "googleDrive") {
            const _options = options as UploadSettings["googleDrive"];
            const result = await post<Task>("/api/tasks/import/google-drive", {
                fileId: _options.fileIds[0],
                filename: _options.filenames[0],
                gtoken: _options.gtoken,
            });

            if (result.error) {
                throw { error: result.error };
            }
            return result;
        }

        if (type === "dropBox") {
            const _options = options as UploadSettings["dropBox"];
            const result = await post<Task>("/api/tasks/import/dropbox", {
                url: _options.url,
                filename: _options.filename,
            });

            if (result.error) {
                throw { error: result.error };
            }

            return result;
        }

        if (type === "url") {
            const _options = options as UploadSettings["url"];
            const result = await post<Task>("/api/tasks/import/url", {
                url: _options.url,
            });

            if (result.error) {
                throw { error: result.error };
            }

            return result;
        }

        const result = await post<Task>("/api/tasks/import/device", {});
        if (result.error) {
            throw { error: result.error };
        }

        return result;
    },
);

export const handleCreateImportTask = (builder: ActionReducerMapBuilder<ImageUploaderState>) => {
    builder.addCase(
        createImportTask.fulfilled,
        (
            state,
            {
                payload: { result },
                meta: {
                    arg: { imageId },
                },
            },
        ) => {
            if (!result) return;
            const task = result;
            state.images = state.images.map((image) => {
                if (image.id !== imageId) return image;

                return {
                    ...image,
                    task,
                    fileState: {
                        ...image.fileState,
                        status: "task_created",
                    },
                };
            });
        },
    );

    builder.addCase(
        createImportTask.pending,
        (
            state,
            {
                meta: {
                    arg: { imageId },
                },
            },
        ) => {
            state.images = state.images.map((image) => {
                if (image.id !== imageId) return image;

                return {
                    ...image,
                    fileState: {
                        ...image.fileState,
                        status: "creating_task",
                    },
                };
            });
        },
    );

    builder.addCase(
        createImportTask.rejected,
        (
            state,
            {
                meta: {
                    arg: { imageId },
                },
            },
        ) => {
            state.images = state.images.map((image) => {
                if (image.id !== imageId) return image;

                return {
                    ...image,
                    fileState: {
                        ...image.fileState,
                        status: "creating_task_failed",
                    },
                };
            });
        },
    );
};
