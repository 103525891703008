import { CropState } from "store/features/crop/types";
import { FlipRotateState } from "../types";

interface Payload {
    payload: CropState["upload"];
}

export const setUploadData = (state: FlipRotateState, { payload: data }: Payload) => {
    state.upload = data;
};
