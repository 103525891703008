import { CompressState } from "../../types";

interface IPayload {
    payload: {
        imageId: string | null;
    };
}

export const setTooltipBounceImageId = (state: CompressState, { payload: { imageId } }: IPayload) => {
    state.tooltipBounceImageId = imageId;
};
