import { CompressState } from "../../types";

interface IPayload {
    payload: string;
}

export const setSelectedImage = (state: CompressState, { payload }: IPayload) => {
    const image = state.images.find((_i) => _i.id === payload);

    if (!image || image.state !== "compressed") return;

    state.selectedImageId = image.id;
};
