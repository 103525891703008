import { Icon, MemeTemplate } from "types/meme";
import { GoogleFont } from "./googleFont";
import { Padding } from "./padding";
import { RecentlyUsedFont } from "./recentlyUsedFont";

export interface MemeGeneratorState {
    meme: Meme | null;
    googleFonts: {
        list: (GoogleFont & { hasLoaded: boolean })[];
        isFetching: boolean;
    };
    recentlyUsedFonts: {
        list: Array<RecentlyUsedFont>;
        isLoading: boolean;
    };
}

export interface DraggingState {
    position?: boolean;
    resize?: ResizeHandlerDirection;
    rotate?: boolean;
}

export interface Meme {
    template: MemeTemplate;
    textBoxes: Array<TextBox>;
    icons: Array<MemeIcon>;
    activeTextBoxId?: string;
    activeIconId?: string;
    padding: Padding & {
        isApplied: boolean;
    };
    dominantColor?: string;
    settings: {
        image: Settings;
        itemsContainer: Settings;
        parentContainerInitial: Settings;
    };
    isDownloading: boolean;
    isDark?: boolean;
}

export interface MemeIcon {
    id: string;
    icon: Icon;
    settings: Settings & {
        rotation: number;
    };
    state: MemeIconState;
    zIndex: number;
    showBorder?: boolean;
    draggingStage?: DraggingState;
}

export enum MemeIconState {
    ACTIVE = "active",
    INACTIVE = "inactive",
}

export interface Settings {
    left: number;
    top: number;
    width: number;
    height: number;
}

export type Alignment = "start" | "middle" | "end";

export interface TextBox {
    id: string;
    left: number;
    top: number;
    width: number;
    height: number;
    rotation: number;
    value: string;
    innerHTML: string;
    fontFamily: string;
    fontColor: string;
    fontSize: number;
    decoration: {
        bold: boolean;
        italic: boolean;
        underline: boolean;
    };
    alignment: {
        horizontal: Alignment;
        vertical: Alignment;
    };
    spacing: {
        letter: number;
        line: number;
    };
    forceCaps: boolean;
    opacity: number;
    outline?: {
        color: string;
        size: number;
        isActive: boolean;
    };
    shadow?: {
        isActive: boolean;
        color: string;
        blurRadius: number;
    };
    state: TextBoxStates;
    showBorder?: boolean;
    showHandlers?: boolean;
    zIndex: number;
    draggingState?: DraggingState;
}

export const resizeHandlerDirections = ["n", "ne", "e", "se", "s", "sw", "w", "nw"] as const;
export type ResizeHandlerDirection = (typeof resizeHandlerDirections)[number];

export enum TextBoxStates {
    BLUR = "blur",
    PRE_INSERT = "pre-insert",
    INSERT = "insert",
}
