import { MemeGeneratorState } from "../../types";

interface Payload {
    payload: NonNullable<MemeGeneratorState["meme"]>["icons"][number];
}

export const addIcon = (state: MemeGeneratorState, { payload }: Payload) => {
    if (!state.meme) throw new Error(`Tried to add icon when meme is ${state.meme}`);
    state.meme.icons.push(payload);
};
