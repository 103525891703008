import type { Job } from "@freeconvert/freeconvert-node/dist/types";
import type { BulkResizeDownloadState } from "../types";

interface Payload {
    payload: {
        job: Job;
    };
}

export const appendJob = (state: BulkResizeDownloadState, { payload }: Payload) => {
    const jobs = state.jobs;
    jobs.push(payload.job);
    state.jobs = jobs;
};
