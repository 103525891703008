import { setMeme } from "./setMeme";
import { setMemeInfo } from "./setMemeInfo";
// textBox
import { setSettings } from "./textBox/setSettings";
import { setActiveTextBoxId } from "./textBox/setActiveTextBoxId";
import { applySettingsToAll } from "./textBox/applySettingsToAll";
import { deleteTextBox } from "./textBox/deleteTextBox";
import { addTextBox } from "./textBox/addTextBox";
// font
import { setLoaded } from "./font/setLoaded";
// recentlyUsedFonts
import { setRecentlyUsedFonts } from "./recentlyUsedFonts/setRecentlyUsedFonts";
import { appendToRecentlyUsedFonts } from "./recentlyUsedFonts/appendToRecentlyUsedFonts";
import { setIsLoading as setIsLoadingRecentlyUsedFonts } from "./recentlyUsedFonts/setIsLoading";
// padding
import { setPadding } from "./padding/setPadding";
import { deletePadding } from "./padding/deletePadding";
// icons
import { addIcon } from "./icons/addIcon";
import { removeIcon } from "./icons/removeIcon";
import { setSettings as setIconSettings } from "./icons/setSettings";
import { editIcon } from "./icons/editIcon";
import { setActiveIconId } from "./icons/setActiveIconId";
import { editDraggingState as editIconDraggingState } from "./icons/editDraggingState";

export const reducers = {
    setMeme,
    setMemeInfo,
    setSettings,
    setLoaded,
    setActiveTextBoxId,
    setRecentlyUsedFonts,
    appendToRecentlyUsedFonts,
    setIsLoadingRecentlyUsedFonts,
    applySettingsToAll,
    deleteTextBox,
    addTextBox,
    setPadding,
    deletePadding,
    addIcon,
    removeIcon,
    setIconSettings,
    editIcon,
    setActiveIconId,
    editIconDraggingState,
};
