import { setColor } from "./resize/setColor";
import { setDimensionType } from "./resize/setDimensionType";
import { setDPI } from "./resize/setDPI";
import { setHeight } from "./resize/setHeight";
import { setIsLocked } from "./resize/setIsLocked";
import { setPercentage } from "./resize/setPercentage";
import { setShouldFillColor } from "./resize/setShouldFillColor";
import { setUnit } from "./resize/setUnit";
import { setWidth } from "./resize/setWidth";
import { setImage } from "./setImage";
import { setFillColorType } from "./resize/setFillColorType";
import { setUploadData } from "./setUploadData";

export const reducers = {
    setColor,
    setDimensionType,
    setDPI,
    setHeight,
    setIsLocked,
    setPercentage,
    setShouldFillColor,
    setUnit,
    setWidth,
    setImage,
    setFillColorType,
    setUploadData,
};
