import { setSort } from "./sort/setSort";
import { setColor } from "./resize/setColor";
import { setDimensionType } from "./resize/setDimensionType";
import { setDPI } from "./resize/setDPI";
import { setHeight } from "./resize/setHeight";
import { setIsLocked } from "./resize/setIsLocked";
import { setPercentage } from "./resize/setPercentage";
import { setShouldFillColor } from "./resize/setShouldFillColor";
import { setUnit } from "./resize/setUnit";
import { setWidth } from "./resize/setWidth";
import { setFillColorType } from "./resize/setFillColorType";
import { deleteImage } from "./images/deleteImage";
import { addImage } from "./images/addImage";
import { deleteAllImages } from "./images/deleteAllImages";
import { setImages } from "./images/setImages";
import { setDimension } from "./images/setDimension";
import { setImageState } from "./images/setImageState";
import { setImporting } from "./images/setImporting";
import { setIsCorrupted } from "./images/setIsCorrupted";
import { setFileUnit } from "./export/setFileUnit";
import { setMaxFileSize } from "./export/setMaxFileSize";
import { setOptimizationLevel } from "./export/setOptimizationLevel";
import { setSaveAs } from "./export/setSaveAs";
import { reset } from "./reset";

export const reducers = {
    setColor,
    setDimensionType,
    setDPI,
    setHeight,
    setIsLocked,
    setPercentage,
    setShouldFillColor,
    setUnit,
    setWidth,
    setFillColorType,
    // filter
    setSort,
    // images
    addImage,
    deleteImage,
    deleteAllImages,
    setImages,
    setDimension,
    setImageState,
    setImporting,
    setIsCorrupted,
    // export settings,
    setFileUnit,
    setMaxFileSize,
    setOptimizationLevel,
    setSaveAs,
    reset,
};
