import type { Job } from "@freeconvert/freeconvert-node/dist/types";
import type { BulkResizeDownloadState } from "../types";

interface Payload {
    payload: {
        jobs: Job[];
    };
}

export const setJobs = (state: BulkResizeDownloadState, { payload: { jobs } }: Payload) => {
    state.jobs = jobs;
};
