import { FileData } from "client/types/FileData";
import { ImageUploaderState } from "../types";

interface IPayload {
    payload: {
        imageId: string;
        fileData: FileData;
    };
}

export const setFile = (state: ImageUploaderState, { payload: { imageId, fileData } }: IPayload) => {
    state.images = state.images.map((image) => {
        if (image.id !== imageId) return image;

        return {
            ...image,
            file: fileData,

            fileState: {
                progress: "continuous",
                status: "added",
                previewSource: fileData?.src ?? "",
                task: null,
            },
        };
    });
};
