import { fileSizeLimit } from "store/features/resizeEditor/reducers/resize/setTargetFileSize";
import { CompressState } from "../../../types";

interface Payload {
    payload: {
        value: number;
        imageId: string;
    };
}

export const setMaxFileSize = (state: CompressState, { payload: { imageId, value } }: Payload) => {
    if (value < 0 || value > fileSizeLimit) return;
    state.images = state.images.map(image => {
        if (image.id !== imageId) return image;
        image.compressionSettings.maxFileSize.value = value;
        return image;
    });
};