import { CompressState } from "../../types";

interface IPayload {
    payload: number;
}

export const setSliderValue = (
    state: CompressState,
    { payload }: IPayload
) => {
    if (payload < 5 || payload > 100) return;

    state.images = state.images.map((_image) => {
        if (_image.id !== state.selectedImageId) return _image;

        return {
            ..._image,
            sliderValue: payload,
        };
    });
};
