import { fileSizeLimit } from "store/features/resizeEditor/reducers/resize/setTargetFileSize";
import { CompressState } from "../../types";

interface Payload {
    payload: number;
}

export const setMaxFileSize = (state: CompressState, { payload: value }: Payload) => {
    if (value < 0 || value > fileSizeLimit) return;
    state.compressionSettings.maxFileSize.value = value;
};