import { MemeGeneratorState } from "../../types";

interface Payload {
    payload: string;
}

export const setLoaded = (state: MemeGeneratorState, { payload }: Payload) => {
    const font = state.googleFonts.list.find((font) => font.family === payload);
    if (!font) return;
    font.hasLoaded = true;
};
