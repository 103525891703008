import { PaddingColor } from "../types/padding";

export const getDefaultPaddingColors = (dominantColor: string): Array<PaddingColor> => [
    {
        label: "Auto Color",
        color: dominantColor,
    },
    {
        label: "White",
        color: "#fff",
    },
    {
        label: "Black",
        color: "#000",
    },
];
