import { CompressState } from "../types";
import { INITIAL_COMPRESS_QUALITY } from "../utils";

export const getCompressionSettings = (ext: string, settings: CompressState["compressionSettings"]): CompressState["compressionSettings"] => {
    if (ext === "png" || settings.type === "quality") {
        return {
            maxFileSize: { value: 0 },
            quality: { value: settings.quality.value },
            type: "quality"
        };
    } else {
        if (settings.maxFileSize.value <= 0) {
            return {
                maxFileSize: { value: 0 },
                quality: { value: INITIAL_COMPRESS_QUALITY },
                type: "quality"
            };
        } else {
            return {
                maxFileSize: { value: settings.maxFileSize.value },
                quality: { value: settings.quality.value },
                type: "max_file_size"
            };
        }
    }
};