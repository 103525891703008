import { BulkResizeState } from "../../types";

interface IPayload {
    payload: string;
}

export const setColor = (
    state: BulkResizeState,
    { payload: color }: IPayload
) => {
    state.resizeSettings.fillColor.color = color;
};
