import { convertFromPixels } from "client/utils/pixels/convertFromPixels";
import { convertToPixels } from "client/utils/pixels/convertToPixels";
import { Unit } from "store/types/unit";
import { BulkResizeState } from "../../types";

interface Payload {
    payload: Unit;
}

export const setUnit = (state: BulkResizeState, { payload: unit }: Payload) => {
    const prevUnit = state.resizeSettings.unit;

    state.resizeSettings = {
        ...state.resizeSettings,
        unit,
        byDimension: {
            ...state.resizeSettings.byDimension,
            width: convertFromPixels(
                convertToPixels(state.resizeSettings.byDimension.width, prevUnit, +state.resizeSettings.dpi),
                unit,
                +state.resizeSettings.dpi,
            ),
            height: convertFromPixels(
                convertToPixels(state.resizeSettings.byDimension.height, prevUnit, +state.resizeSettings.dpi),
                unit,
                +state.resizeSettings.dpi,
            ),
        },
    };

    state.images = state.images.map((image) => {
        return {
            ...image,
            preview: {
                ...image.preview,
                width: convertFromPixels(
                    convertToPixels(image.preview.width, prevUnit, +state.resizeSettings.dpi),
                    unit,
                    +state.resizeSettings.dpi,
                ),
                height: convertFromPixels(
                    convertToPixels(image.preview.height, prevUnit, +state.resizeSettings.dpi),
                    unit,
                    +state.resizeSettings.dpi,
                ),
            },
        };
    });
};
