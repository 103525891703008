import { setSource } from "./setSource";
import { setImage } from "./setImage";
import { setSelectedColor } from "./setSelectedColor";
import { setCurrentColor } from "./setCurrentColor";
import { setIsImageLoading } from "./setIsImageLoading";
import { setUploadData } from "./setUploadData";

export const reducers = {
    setSource,
    setImage,
    setSelectedColor,
    setCurrentColor,
    setIsImageLoading,
    setUploadData,
};
