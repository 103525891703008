import { getSortedImages } from "store/utils/getSortedImages";
import { CompressImage, CompressState } from "../../types";

interface IPayload {
    payload: CompressImage[];
}

export const setImages = (state: CompressState, { payload: images }: IPayload) => {
    state.images = getSortedImages(state.sort, images);
    state.fromDownloadPage = true;
};
