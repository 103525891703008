import { PreviewImage } from "store/types/previewImage";
import { EnlargeState } from "../types";

interface IPayload {
    payload: PreviewImage;
}

export const setImage = (state: EnlargeState, { payload }: IPayload) => {
    state.image = payload;
    state.resizeSettings = {
        ...state.resizeSettings,
        byDimension: {
            ...state.resizeSettings.byDimension,
            height: payload.original.height,
            width: payload.original.width,
            lastModified: "width",
        },
    };
    state.fromEditor = true;
};
