import { ResizeEditorState, ResizeEditType } from "../../types";

interface IPayload {
    payload: ResizeEditType;
}

export const setEditType = (
    state: ResizeEditorState,
    { payload: editType }: IPayload
) => {
    state.currentType = editType;
};
