import { CompressState } from "../../types";

interface IPayload {
    payload: string;
}

export const deleteImage = (state: CompressState, { payload }: IPayload) => {
    state.images = state.images.filter((_image) => _image.id !== payload);

    if (state.images[0]) {
        state.selectedImageId = state.images[0].id;
    } else {
        state.selectedImageId = null;
    }
};
