import { CropState } from "./types";

export const initialState: CropState = {
    image: null,
    crop: {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        ratio: "freeform",
        cropBox: null,
        customRatioValues: {
            x: 1,
            y: 1
        }
    },
    fromEditor: false,
    upload: {
        type: "device",
        settings: {},
    },
};
