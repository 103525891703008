import { Task } from "@freeconvert/freeconvert-node/dist/types";
import { formatBytes } from "client/utils/formatBytes";
import { CompressState } from "../../types";

interface IPayload {
    payload: {
        imageId: string;
        importTask: Task;
        src: string;
    };
}

export const complete = (state: CompressState, { payload: { imageId, importTask, src } }: IPayload) => {
    const _image = state.images.find((_image) => _image.id === imageId);
    if (!_image) return;

    _image.state = "imported";
    _image.src = src;
    _image.original = {
        ..._image.original,
        size: importTask.result?.size ? formatBytes(importTask.result.size) : "0 KB",
    };
    _image.preview = {
        ..._image.preview,
        size: importTask.result?.size ? formatBytes(importTask.result.size) : "0 KB",
    };
    _image.importTask = importTask;
};
