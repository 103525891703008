import { convertFromPixels } from "@/utils/pixels/convertFromPixels";
import { MAX_RESIZE_PERCENTAGE } from "store/features/resizeEditor/reducers/resize/setPercentage";
import { BulkResizeState } from "../../types";

interface IPayload {
    payload: number;
}

export const setPercentage = (state: BulkResizeState, { payload: percentage }: IPayload) => {
    if (!percentage || percentage < 0 || percentage > MAX_RESIZE_PERCENTAGE) return;

    state.resizeSettings.byPercentage.percentage = percentage;
    state.lastModified = "percentage";

    const unit = state.resizeSettings.unit;
    const dpi = state.resizeSettings.dpi;

    state.images = state.images.map((image) => {
        const originalWidth = convertFromPixels(image.original.width, unit, +dpi);
        const originalHeight = convertFromPixels(image.original.height, unit, +dpi);
        const width = (originalWidth * percentage) / 100;
        const height = (originalHeight * percentage) / 100;

        return {
            ...image,
            preview: {
                ...image.preview,
                width,
                height,
            },
        };
    });
};
