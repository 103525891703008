import { setFile } from "./setFile";
import { setUploadType } from "./setUploadtype";
import { setFileState } from "./setFileState";
import { removeFile } from "./removeFile";
import { addImage } from "./addImage";
import { deleteImage } from "./deleteImage";
import { deleteAllImages } from "./deleteAllImages";
import { reset } from "./reset";
import { showModal as showInvalidImageExtensionModal } from "./invalidImageExtensionModal/showModal";
import { hideModal as hideInvalidImageExtensionModal } from "./invalidImageExtensionModal/hideModal";

export const reducers = {
    setUploadType,
    setFile,
    setFileState,
    removeFile,
    addImage,
    deleteImage,
    deleteAllImages,
    reset,
    showInvalidImageExtensionModal,
    hideInvalidImageExtensionModal,
};
