import { ImageUploaderState } from "../types";

interface IPayload {
    payload: {
        taskId: string;
    };
}

// also deletes task
export const removeFile = (state: ImageUploaderState, { payload: { taskId } }: IPayload) => {
    state.images = state.images.filter((image) => {
        return image.task?.id !== taskId;
    });
};
