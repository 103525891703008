import { ImageProperties } from "store/types/previewImage";
import { ResizeDownloadState } from "../types/types";

interface IPayload {
    payload: Partial<ImageProperties>;
}

export const setOriginal = (state: ResizeDownloadState, { payload: properties }: IPayload) => {
    state.image.original = {
        ...state.image.original,
        ...properties,
    };
};
