import { ResizeEditorState, ResizeEditType } from "../../types";

interface IPayload {
    payload: ResizeEditType;
}

export const push = (
    state: ResizeEditorState,
    { payload: type }: IPayload
) => {
    state.history = [...state.history, type];
};
