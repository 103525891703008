import { convertFromPixels } from "client/utils/pixels/convertFromPixels";
import { roundFloat } from "client/utils/roundFloat";
import { initialState } from "store/features/resizeEditor/initialState";
import { ResizeEditorState } from "../../types";

export const reset = (state: ResizeEditorState) => {
    if (!state.image) return;
    const width = convertFromPixels(state.image.initialWidth, state.resize.unit);
    const height = convertFromPixels(state.image.initialHeight, state.resize.unit);

    state.resize.byDimension = {
        ...state.resize.byDimension,
        width: state.resize.unit === "pixels" ? Math.round(width) : roundFloat(width),
        height: state.resize.unit === "pixels" ? Math.round(height) : roundFloat(height),
    };
    state.crop.ratio = "freeform";
    state.crop.customRatioValues = initialState.crop.customRatioValues;
};
