import { Settings } from ".";
import { getDefaultPaddingColors } from "../data/getDefaultPaddingColors";

export enum PaddingType {
    NONE = "none",
    TOP = "top",
    BOTTOM = "bottom",
    TOP_AND_BOTTOM = "top_and_bottom",
}

export interface Padding {
    type: PaddingType;
    value: number;
    color: PaddingColor;
    instances: PaddingInstance[];
}

export interface PaddingInstance extends Settings {
    type: PaddingType.TOP | PaddingType.BOTTOM;
}

export interface PaddingColor {
    color: string;
    label: string;
}

export const DEFAULT_PADDING_VALUE = 50;

export function getDefaultPaddingValues(dominantColor: string):Padding {
    return {
        type: PaddingType.NONE,
        color: getDefaultPaddingColors(dominantColor)[0],
        value: DEFAULT_PADDING_VALUE,
        instances: [],
    };
}
