import { ResizeEditorState } from "../../types";

interface IPayload {
    payload: number;
}

export const fileSizeLimit = 256000; // bytes

export const setTargetFileSize = (state: ResizeEditorState, { payload: targetFileSize }: IPayload) => {
    if (targetFileSize > (state.resize.exportSettings.fileUnit === "KB" ? fileSizeLimit : Math.floor(fileSizeLimit / 1000))) return;
    state.resize.exportSettings.targetFileSize = targetFileSize;
};
