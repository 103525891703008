import { useMounted } from "client/hooks/utils/useMounted";
import { createPortal } from "react-dom";
import { ToastComponent } from "./ToastComponent";
import { useAppSelector } from "store/store";
import { toastsSelector } from "store/features/toasts/selectors/toastsSelector";
import { modalPortalContainerId } from "client/config/divIds";

export const DEFAULT_TOAST_SHOW_TIME = 7000; // 7 seconds

export const ToastsRenderer = () => {
    const { mounted } = useMounted();
    const element = typeof window === "undefined" ? null : document.getElementById(modalPortalContainerId);
    const { toasts } = useAppSelector(toastsSelector);

    if (!element || !mounted) return null;

    return createPortal(
        <div className="fixed z-50">
            <div className="fixed top-5 left-5 flex flex-col items-center">
                {toasts
                    .filter((t) => t.position === "top-left")
                    .slice(0, 3)
                    .map((toast) => {
                        return <ToastComponent toast={toast} key={toast.id} />;
                    })}
            </div>

            <div className="fixed top-5 left-0 right-0 flex flex-col items-center">
                {toasts
                    .filter((t) => t.position === "top-center")
                    .slice(0, 3)
                    .map((toast) => {
                        return <ToastComponent toast={toast} key={toast.id} />;
                    })}
            </div>

            <div className="fixed top-5 right-5 flex flex-col items-center">
                {toasts
                    .filter((t) => t.position === "top-right")
                    .slice(0, 3)
                    .map((toast) => {
                        return <ToastComponent toast={toast} key={toast.id} />;
                    })}
            </div>

            <div className="fixed bottom-5 right-5 flex flex-col items-center">
                {toasts
                    .filter((t) => t.position === "bottom-right")
                    .slice(0, 3)
                    .reverse()
                    .map((toast) => {
                        return <ToastComponent toast={toast} key={toast.id} />;
                    })}
            </div>

            <div className="fixed bottom-5 left-0 right-0 flex flex-col items-center">
                {toasts
                    .filter((t) => t.position === "bottom-center")
                    .slice(0, 3)
                    .reverse()
                    .map((toast) => {
                        return <ToastComponent toast={toast} key={toast.id} />;
                    })}
            </div>

            <div className="fixed bottom-5 left-5 flex flex-col items-center">
                {toasts
                    .filter((t) => t.position === "bottom-left")
                    .slice(0, 3)
                    .reverse()
                    .map((toast) => {
                        return <ToastComponent toast={toast} key={toast.id} />;
                    })}
            </div>
        </div>,
        element,
    );
};
