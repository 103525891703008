import { DraggingState, MemeGeneratorState } from "../../types";

interface Payload {
    payload: {
        id: string;
        draggingState: DraggingState;
    };
}

export const editDraggingState = (state: MemeGeneratorState, { payload: { id, draggingState } }: Payload) => {
    const icon = state.meme?.icons.find((_i) => _i.id === id);
    if (!icon) return;
    icon.draggingStage = {
        ...icon.draggingStage,
        ...draggingState,
    };
};
