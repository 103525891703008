import type { Job } from "@freeconvert/freeconvert-node/dist/types";
import type { BulkResizeDownloadState } from "../types";

interface Payload {
    payload: {
        job: Partial<Omit<Job, "id">> & Pick<Job, "id">;
    };
}

export const editJob = (state: BulkResizeDownloadState, { payload }: Payload) => {
    state.jobs = state.jobs.map((job) => {
        if (job.id !== payload.job.id) return job;
        return {
            ...job,
            ...payload.job,
        };
    });
};
