import { ResizeEditorState } from "../../types";

interface IPayload {
    payload: string;
}

export const setColor = (
    state: ResizeEditorState,
    { payload: color }: IPayload
) => {
    state.resize.fillColor.color = color;
};
