import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./extraReducers";
import { reducers } from "./reducers";
import { initialState } from "./initialState";

export const resizeDownloadSlice = createSlice({
    name: "resizeDownload",
    initialState,
    reducers,
    extraReducers,
});
