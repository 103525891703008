import { MemeGeneratorState, MemeIconState, TextBoxStates } from "../../types";
import { getHighestZIndexElement } from "../../utils/getHighestZIndexElement";

interface Payload {
    payload: { id: string } & Partial<
        Omit<NonNullable<MemeGeneratorState["meme"]>["icons"][number], "icon" | "zIndex">
    >;
}

export const editIcon = (state: MemeGeneratorState, { payload }: Payload) => {
    const meme = state.meme;
    if (!meme) throw new Error(`Tried to edit icon when icon is ${state.meme}`);
    meme.icons = meme.icons.map((icon) => {
        if (icon.id !== payload.id) {
            if (typeof payload.state !== "undefined" && payload.state === MemeIconState.ACTIVE) {
                icon.state = MemeIconState.INACTIVE;
                icon.showBorder = false;
            }
            return icon;
        }
        icon = {
            ...icon,
            ...payload,
        };
        if (typeof payload.state !== "undefined" && payload.state === MemeIconState.ACTIVE) {
            icon.zIndex = (getHighestZIndexElement(state)?.zIndex || 0) + 1;
            meme.activeIconId = icon.id;
            if (typeof meme.activeTextBoxId !== "undefined") {
                const activeTextBox = meme.textBoxes.find((_t) => _t.id === meme.activeTextBoxId);
                if (activeTextBox) {
                    activeTextBox.state = TextBoxStates.BLUR;
                }
                meme.activeTextBoxId = undefined;
            }
        }
        return icon;
    });
};
