import { CropBox } from "store/types/crop";
import { ResizeEditorState } from "../../types";

interface Payload {
    payload: {
        cropBox: CropBox | null;
    };
}

export const setCropBox = (state: ResizeEditorState, { payload: { cropBox } }: Payload) => {
    state.crop.cropBox = cropBox;
    state.operations.push({
        name: "crop",
        value: {
            cropBox,
            rotation: state.rotate.rotation,
        },
    });
};
