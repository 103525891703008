import { ResizeEditorState } from "../../types";

interface IPayload {
    payload: number;
}

export const MAX_RESIZE_PERCENTAGE = 200;

export const setPercentage = (state: ResizeEditorState, { payload: percentage }: IPayload) => {
    if (!percentage || percentage < 0 || percentage > MAX_RESIZE_PERCENTAGE) return;

    state.resize.byPercentage.percentage = percentage;
};
