import { Sort } from "store/types/sort";
import { getSortedImages } from "store/utils/getSortedImages";
import { CompressState } from "../../types";

interface IPayload {
    payload: Sort;
}

export const setSort = (
    state: CompressState,
    { payload }: IPayload
) => {
    state.images = [...getSortedImages(payload, state.images)];
    state.sort = payload;
};
