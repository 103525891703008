import { CompressState } from "../../types";

interface IPayload {
    payload: {
        imageId: string;
        progress: "continuous" | number;
    };
}

export const setProgress = (state: CompressState, { payload: { imageId, progress } }: IPayload) => {
    const _image = state.images.find((_image) => _image.id === imageId);
    if (!_image) return;
    _image.importing = {
        ...(_image.importing ?? {}),
        progress,
    };
};
