import { CanvasImage } from "store/types/crop";
import { CropState } from "../../types";

interface IPayload {
    payload: CanvasImage;
}

export const setImage = (state: CropState, { payload: image }: IPayload) => {
    state.image = image;
    state.fromEditor = true;
};
