import { BulkResizeState } from "../../types";

interface Payload {
    payload: {
        id: string;
        isCorrupted: boolean;
    };
}

export const setIsCorrupted = (store: BulkResizeState, { payload: { id, isCorrupted } }: Payload) => {
    const image = store.images.find((image) => image.id === id);
    if (!image) return;
    image.isCorrupted = isCorrupted;
};
