import { PreviewImage } from "store/types/previewImage";
import { ColorPickerState } from "../types";

interface IPayload {
    payload: PreviewImage;
}

export const setImage = (state: ColorPickerState, { payload }: IPayload) => {
    state.image = payload;
};
