import { convertFromPixels } from "client/utils/pixels/convertFromPixels";
import { convertToPixels } from "client/utils/pixels/convertToPixels";
import { Unit } from "store/types/unit";
import { EnlargeState } from "../../types";

interface IPayload {
    payload: Unit;
}

export const setUnit = (state: EnlargeState, { payload: unit }: IPayload) => {
    const prevUnit = state.resizeSettings.unit;

    state.resizeSettings = {
        ...state.resizeSettings,
        unit,
        byDimension: {
            ...state.resizeSettings.byDimension,
            width: convertFromPixels(convertToPixels(state.resizeSettings.byDimension.width, prevUnit), unit),
            height: convertFromPixels(convertToPixels(state.resizeSettings.byDimension.height, prevUnit), unit),
            lastModified: state.resizeSettings.byDimension.lastModified,
        },
    };

    state.image = {
        ...state.image,
        preview: {
            ...state.image.preview,
            width: convertFromPixels(convertToPixels(state.image.preview.width, prevUnit), unit),
            height: convertFromPixels(convertToPixels(state.image.preview.height, prevUnit), unit),
        },
    };
};
