import { FlipRotateEditType, FlipRotateState } from "../types";

interface IPayload {
  payload: FlipRotateEditType;
}

export const setCurrentType = (
  state: FlipRotateState,
  { payload }: IPayload
) => {
    state.currentType = payload;
}