import { Unit } from "store/types/unit";
import { INCH_TO_MM } from "./convertFromPixels";

export const convertToPixels = (value: number, unit: Unit, _dpi?: number): number => {
    const dpi = typeof _dpi === "undefined" ? 1 : _dpi > 0 ? _dpi : 1;

    if (unit === "cm") {
        // return cmToPx(value);
        return (value / (INCH_TO_MM / 10)) * dpi;
    }

    if (unit === "mm") {
        return (value / INCH_TO_MM) * dpi;
    }

    if (unit === "inch") {
        return value * dpi;
    }

    return value;
};

const cmToPx = (value: number) => {
    return value * 37.7952755906;
};
