import { createSlice } from "@reduxjs/toolkit";

interface FreshworksState {
    state: "uninitialized" | "loading" | "loaded";
}

const initialState: FreshworksState = {
    state: "uninitialized",
};

export const changeState = (currentState: FreshworksState, { payload }: { payload: FreshworksState["state"] }) => {
    currentState.state = payload;
};

export const freshworksSlice = createSlice({
    name: "freshworks",
    initialState,
    reducers: {
        changeState,
    },
});

export const actions = freshworksSlice.actions;

