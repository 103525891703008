import { EnlargeState } from "../../types";

interface IPayload {
    payload: number;
}

export const setWidth = (state: EnlargeState, { payload: _width }: IPayload) => {
    let width = _width;
    if (!width || width < 0) {
        width = 0;
    }

    state.resizeSettings.byDimension.width = width;

    state.resizeSettings.byDimension.lastModified = "width";
    state.lastModified = "width";

    const isLocked = state.resizeSettings.isLocked;

    const ratio = state.image.original.width / state.image.original.height;
    const height = isLocked ? Math.floor(width / ratio) : state.resizeSettings.byDimension.height;

    state.image = {
        ...state.image,
        preview: {
            ...state.image.preview,
            width,
            height,
        },
    };

    state.resizeSettings.byDimension = {
        ...state.resizeSettings.byDimension,
        width,
        height,
        lastModified: "width",
    };
};
