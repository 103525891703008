import Cropper from "cropperjs";
import { CropRatioType } from "store/types/crop";
import { CropState } from "../../types";

interface IPayload {
    payload: {
        ratio: CropRatioType;
        cropBoxData: Cropper.CropBoxData;
    };
}

export const setRatio = (state: CropState, { payload: { ratio, cropBoxData } }: IPayload) => {
    if (!state.image) return;

    state.crop.ratio = ratio;
    state.crop = {
        ...state.crop,
        ratio,
        width: cropBoxData.width * (state.image.originalWidth / state.image.initialWidth),
        height: cropBoxData.height * (state.image.originalHeight / state.image.initialHeight),
        left: cropBoxData.left * (state.image.originalWidth / state.image.initialWidth),
        top: cropBoxData.top * (state.image.originalHeight / state.image.initialHeight),
    };
};
