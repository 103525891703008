import type { Job } from "@freeconvert/freeconvert-node/dist/types";
import { formatBytes } from "client/utils/formatBytes";
import { sizeToBytes } from "client/utils/sizeToBytes";
import { CompressImage, CompressState } from "../../types";
import { getExportTaskFromJob } from "client/utils/getExportTaskFromJob";

interface SetCompressedPayload {
    payload: {
        image: CompressImage;
        job: Job;
        settings: CompressState["compressionSettings"];
    };
}

export const setCompressed = (state: CompressState, { payload }: SetCompressedPayload) => {
    state.images = state.images.map((image) => {
        if (image.id !== payload.image.id) return image;
        const compressTask = payload.job.tasks?.find(({ name }) => name === "compress");
        const exportTask = getExportTaskFromJob(payload.job);
        if (!compressTask || !exportTask) return image;
        let originalSize = sizeToBytes(image.original.size) ?? image.importTask?.result?.size;
        const diff = originalSize - (compressTask.result?.size ?? 0);
        const afterCompressQuality = (diff / originalSize) * 100;
        const src = exportTask.result?.url?.toString() || "";
        return {
            ...image,
            compressed: {
                quality: Math.floor(isNaN(afterCompressQuality) ? 0 : afterCompressQuality),
                size: formatBytes(compressTask.result?.size || 0),
                src,
                job: payload.job,
            },
            state: "compressed",
            sliderValue: payload.settings.type === "quality" ? payload.settings.quality.value : image.sliderValue,
        };
    });
    const firstImage = state.images[0];
    if (!state.selectedImageId && firstImage && firstImage.state === "compressed") {
        state.selectedImageId = firstImage.id;
    }
};
