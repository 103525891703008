import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./extraReducers";
import { initialState } from "./initialState";
import { reducers } from "./reducers";

export const bulkResizeSlice = createSlice({
    name: "bulkResize",
    initialState,
    reducers: reducers,
    extraReducers: extraReducers,
});
