import { DPI } from "store/types/dpi";
import { ResizeEditorState } from "../../types";

interface IPayload {
    payload: DPI;
}

export const setDPI = (
    state: ResizeEditorState,
    { payload: dpi }: IPayload
) => {
    state.resize.dpi = dpi;
};
