import { EnlargeState } from "./types";

export const initialState: EnlargeState = {
    resizeSettings: {
        byDimension: {
            height: 0,
            width: 0,
            lastModified: "width",
        },
        byPercentage: {
            percentage: 100,
        },
        dpi: "300",
        unit: "pixels",
        dimensionType: "pixels",
        fillColor: {
            color: "#ffc700",
            shouldFillColor: true,
            type: "color"
        },
        isLocked: true,
    },
    image: {
        createdAt: new Date(),
        format: ".jpg",
        original: {
            height: 0,
            width: 0,
            size: "0 KB",
        },
        preview: {
            height: 0,
            width: 0,
            size: "0 KB",
        },
        src: "",
        title: "",
    },
    lastModified: null,
    fromEditor: false,
    upload: {
        type: "device",
        settings: {}
    }
};
