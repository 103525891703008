import { MemeGeneratorState, TextBox, TextBoxStates } from "../../types";

interface Payload {
    payload: Partial<TextBox> & Pick<TextBox, "id">;
}

export const setSettings = (state: MemeGeneratorState, { payload }: Payload) => {
    if (!state.meme) return;
    state.meme.textBoxes = state.meme.textBoxes.map((textBox) => {
        if (textBox.id !== payload.id) return textBox;
        return {
            ...textBox,
            ...payload,
        };
    });
};
