import { MemeGeneratorState, TextBox } from "../../types";

interface Payload {
    payload: string;
}

export const applySettingsToAll = (state: MemeGeneratorState, { payload: id }: Payload) => {
    if (!state.meme) throw new Error("Meme not found");
    const textBox = state.meme.textBoxes.find((_t) => _t.id === id);
    if (!textBox) return;
    state.meme.textBoxes = state.meme.textBoxes.map((_t): TextBox => {
        if (_t.id === id) return _t;
        return {
            ...textBox,
            id: _t.id,
            value: _t.value,
            innerHTML: _t.innerHTML,
            left: _t.left,
            top: _t.top,
            width: _t.width,
            height: _t.height,
            rotation: _t.rotation,
        };
    });
};
