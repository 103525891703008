import { BulkResizeImage, BulkResizeState } from "../../types";

interface Payload {
    payload: {
        imageId: string;
        imageState: BulkResizeImage["state"];
    };
}

export const setImageState = (state: BulkResizeState, { payload: { imageId, imageState } }: Payload) => {
    state.images = state.images.map((_image) => {
        if (_image.id !== imageId) return _image;

        return {
            ..._image,
            state: imageState,
        };
    });
};
