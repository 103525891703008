import type { ResizeEditorState } from "../../types";

interface IPayload {
    payload: {
        value: number;
    };
}

export const setRatio = (state: ResizeEditorState, { payload: { value } }: IPayload) => {
    if (!state.image) return;

    state.image.ratio = value;
};
