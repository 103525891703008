import { CompressState } from "../../../types";

interface Payload {
    payload: {
        imageId: string;
        value: number;
    };
}

export const setQuality = (state: CompressState, { payload: { imageId, value } }: Payload) => {
    if (value < 5) return;
    state.images = state.images.map(image => {
        if (image.id !== imageId) return image;
        image.compressionSettings.quality.value = value;
        return image;
    });
};