import { initialState } from "../initialState";
import { BulkResizeState } from "../types";

export const reset = (state: BulkResizeState) => {
    state.exportSettings = initialState.exportSettings;
    state.images = initialState.images;
    state.immutableImages = initialState.immutableImages;
    state.lastModified = initialState.lastModified;
    state.resizeSettings = initialState.resizeSettings;
    state.sort = initialState.sort;
};
