import { fileSizeLimit } from "store/features/resizeEditor/reducers/resize/setTargetFileSize";
import { BulkResizeState } from "../../types";

interface IPayload {
    payload: number;
}

export const setMaxFileSize = (state: BulkResizeState, { payload }: IPayload) => {
    if (payload > (state.exportSettings.fileUnit === "KB" ? fileSizeLimit : Math.floor(fileSizeLimit / 1000))) return;
    state.exportSettings.maxFileSize = payload;
};
