import { fileSizeLimit } from "store/features/resizeEditor/reducers/resize/setTargetFileSize";
import { FileUnit, BulkResizeState } from "../../types";

interface IPayload {
    payload: FileUnit;
}

export const setFileUnit = (state: BulkResizeState, { payload }: IPayload) => {
    state.exportSettings.fileUnit = payload;

    if (state.exportSettings.maxFileSize !== 0) {
        if (payload === "MB" && state.exportSettings.maxFileSize > fileSizeLimit / 1000) {
            state.exportSettings.maxFileSize = fileSizeLimit / 1000;
        }
    }
};
