import { Meme, MemeGeneratorState } from "../types";

interface Payload {
    payload: Partial<Meme>;
}

export const setMemeInfo = (state: MemeGeneratorState, { payload }: Payload) => {
    if (!state.meme) return;
    state.meme = {
        ...state.meme,
        ...payload,
    };
};
