import { FlipRotateState } from "../types";

export const restoreState = (state: FlipRotateState) => {
    const backupState = state.backupState;
    if (!backupState) return;
    state.image = backupState.image;
    state.flip = backupState.flip;
    state.rotate = backupState.rotate;
    state.currentType = backupState.currentType;
    state.isLoading = backupState.isLoading;
    state.upload = backupState.upload;
    state.backupState = undefined;
};
