import { ResizeEditorState } from "./types";

const BLACK = "#000000";
const DEFAULT_COLOR = BLACK;

export const initialState: ResizeEditorState = {
    image: null,
    resize: {
        byDimension: {
            width: 0,
            height: 0,
            lastModified: "width",
        },
        byPercentage: {
            percentage: 100,
        },
        dimensionType: "pixels",
        dpi: "300",
        unit: "pixels",
        isLocked: true,
        exportSettings: {
            saveAs: "original",
            targetFileSize: "",
            fileUnit: "KB",
            optimizationLevel: "custom",
        },
        fillColor: {
            color: DEFAULT_COLOR,
            shouldFillColor: true,
            type: "color"
        },
    },
    crop: {
        height: 0,
        width: 0,
        top: 0,
        left: 0,
        ratio: "freeform",
        cropBox: null,
        customRatioValues: {
            x: 1,
            y: 1
        }
    },
    flip: {
        horizontal: false,
        vertical: false,
    },
    rotate: {
        rotation: 0,
        straighten: 0,
    },
    currentType: "resize",
    history: [],
    editModal: false,
    operations: [],
};