import { ResizeEditorState } from "../../types";

interface Payload {
    payload: {
        width: number;
        height: number;
        top: number;
        left: number;
    };
}

export const handleCropBoxChange = (state: ResizeEditorState, { payload }: Payload) => {
    state.crop = {
        ...state.crop,
        cropBox: state.crop.cropBox,
        width: payload.width < 0 ? 0 : payload.width,
        height: payload.height < 0 ? 0 : payload.height,
        top: payload.top < 0 ? 0 : payload.top,
        left: payload.left < 0 ? 0 : payload.left,
    };
};
