import { getDefaultPaddingColors } from "../../data/getDefaultPaddingColors";
import { Meme, MemeGeneratorState } from "../../types";
import { PaddingInstance, PaddingType } from "../../types/padding";

interface Payload {
    payload: Partial<Meme["padding"]>;
}

export const setPadding = (state: MemeGeneratorState, { payload }: Payload) => {
    if (!state.meme) throw new Error("Tried to access padding while no meme");
    const padding: Meme["padding"] = {
        ...state.meme.padding,
        ...payload,
    };
    if (
        state.meme.dominantColor &&
        padding.color.label === getDefaultPaddingColors(state.meme.dominantColor)[0].label
    ) {
        padding.color.color = state.meme.dominantColor;
    }

    if (padding.type === PaddingType.TOP) {
        const paddingHeight = (state.meme.settings.image.height * padding.value) / 2 / 100;
        const itemsTotalHeight = state.meme.settings.image.height + paddingHeight;
        const topInstance: PaddingInstance = {
            type: PaddingType.TOP,
            top: 0,
            left: state.meme.settings.image.left,
            width: state.meme.settings.image.width,
            height: paddingHeight,
        };
        padding.instances = [topInstance];
        state.meme.settings.image.top = topInstance.top + topInstance.height;
        state.meme.settings.itemsContainer.height = itemsTotalHeight;
        state.meme.settings.itemsContainer.top =
            itemsTotalHeight >= state.meme.settings.parentContainerInitial.height
                ? 0
                : (state.meme.settings.parentContainerInitial.height - itemsTotalHeight) / 2;
    } else if (padding.type === PaddingType.BOTTOM) {
        const paddingHeight = (state.meme.settings.image.height * padding.value) / 2 / 100;
        const itemsTotalHeight = state.meme.settings.image.height + paddingHeight;
        const bottomInstance: PaddingInstance = {
            type: PaddingType.BOTTOM,
            top: state.meme.settings.image.height,
            left: 0,
            width: state.meme.settings.image.width,
            height: paddingHeight,
        };
        padding.instances = [bottomInstance];
        state.meme.settings.image.top = 0;
        state.meme.settings.itemsContainer.height = itemsTotalHeight;
        state.meme.settings.itemsContainer.top =
            itemsTotalHeight >= state.meme.settings.parentContainerInitial.height
                ? 0
                : (state.meme.settings.parentContainerInitial.height - itemsTotalHeight) / 2;
    } else if (padding.type === PaddingType.TOP_AND_BOTTOM) {
        const paddingHeight = (state.meme.settings.image.height * padding.value) / 100;
        const itemsTotalHeight = state.meme.settings.image.height + paddingHeight;
        const topInstance: PaddingInstance = {
            type: PaddingType.TOP,
            top: 0,
            left: state.meme.settings.image.left,
            width: state.meme.settings.image.width,
            height: paddingHeight / 2,
        };
        const bottomInstance: PaddingInstance = {
            type: PaddingType.BOTTOM,
            top: topInstance.height + state.meme.settings.image.height,
            left: 0,
            width: state.meme.settings.image.width,
            height: paddingHeight / 2,
        };
        padding.instances = [topInstance, bottomInstance];
        state.meme.settings.image.top = topInstance.top + topInstance.height;
        state.meme.settings.itemsContainer.height = itemsTotalHeight;
        state.meme.settings.itemsContainer.top =
            itemsTotalHeight >= state.meme.settings.parentContainerInitial.height
                ? 0
                : (state.meme.settings.parentContainerInitial.height - itemsTotalHeight) / 2;
    } else if (padding.type === PaddingType.NONE) {
        padding.instances = [];
        state.meme.settings.itemsContainer.height = state.meme.settings.image.height;
        state.meme.settings.image.top = 0;
    }
    state.meme.padding = padding;
};
