import { Task } from "@freeconvert/freeconvert-node/dist/types";
import { FileState, ImageUploaderState } from "../types";

interface IPayload {
    payload: {
        task: Task;
        fileState: Partial<FileState>;
    };
}

export const setFileState = (state: ImageUploaderState, { payload: { task, fileState } }: IPayload) => {
    state.images = state.images.map((image) => {
        if (image.task?.id !== task.id) return image;

        return {
            ...image,
            fileState: {
                ...image.fileState,
                ...fileState,
            },
            task,
        };
    });
};
