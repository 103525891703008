import { setPreview } from "./setPreview";
import { setSrc } from "./setSrc";
import { setImageInfo } from "./setImageInfo";
import { setOriginal } from "./setOriginal";
import { reset } from "./reset";

export const reducers = {
    setPreview,
    setSrc,
    setImageInfo,
    setOriginal,
    reset,
};
