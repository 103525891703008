import { ResizeEditorState } from "../../types";

interface IPayload {
    payload: number;
}

export const setRotation = (state: ResizeEditorState, { payload: rotation }: IPayload) => {
    state.operations.push({
        name: "rotate",
        value: {
            rotation,
            direction:
                rotation === 0
                    ? state.rotate.rotation === 270 || state.rotate.rotation === -90
                        ? "clock-wise"
                        : "counter-clock-wise"
                    : rotation > state.rotate.rotation
                    ? "clock-wise"
                    : "counter-clock-wise",
            flip: {
                horizontal: state.flip.horizontal,
                vertical: state.flip.vertical,
            },
        },
    });
    state.rotate.rotation = rotation;
};
