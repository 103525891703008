import { MemeGeneratorState } from "../../types";

export const deletePadding = (state: MemeGeneratorState) => {
    if (!state.meme) throw new Error("Meme not found while deleting padding");
    state.meme.padding = {
        ...state.meme.padding,
        isApplied: false,
        instances: [],
    };
    state.meme.settings.itemsContainer.height = state.meme.settings.image.height;
    state.meme.settings.image.top = 0;
};
