import { ResizeEditorState } from "../../types";

interface IPayload {
    payload: number;
}

export const setWidth = (state: ResizeEditorState, { payload: _width }: IPayload) => {
    let width = _width;
    if (!width || width < 0) {
        width = 0;
    }
    if (state.currentType === "crop") {
        state.resize.byDimension.width = width;
        return;
    }

    let widthResult = width;
    let heightResult = state.resize.byDimension.height;

    if (state.resize.isLocked && state.image) {
        heightResult = width / state.image.ratio;
    }
    state.resize.byDimension.width = widthResult;
    state.resize.byDimension.height = heightResult;

    state.resize.byDimension.lastModified = "width";
};
