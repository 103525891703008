import { ImageExtension } from "store/types/imageExtension";
import { ResizeDownloadState } from "../types/types";

interface IPayload {
    payload: {
        src?: string;
        format?: ImageExtension;
        title?: string;
    };
}

export const setImageInfo = (state: ResizeDownloadState, { payload: { src, format, title } }: IPayload) => {
    state.image = {
        ...state.image,
        src: src ?? state.image.src,
        format: format ?? state.image.format,
        title: title ?? state.image.title,
    };
};
