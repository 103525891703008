import { ToastsState, Toast } from "../types";

interface Payload {
    payload: {
        id: string;
    };
}

export const removeToast = (state: ToastsState, { payload: { id } }: Payload) => {
    state.toasts = state.toasts.filter((toast) => toast.id !== id);
};
