import { ImageUploaderState } from "../types";

interface IPayload {
    payload: string;
}

export const deleteImage = (state: ImageUploaderState, { payload: id }: IPayload) => {
    state.images = state.images.filter((_image) => {
        return _image.id !== id;
    });
};
