import { BulkResizeState, OptimizationLevel } from "../../types";

interface Payload {
    payload: OptimizationLevel;
}

export const setOptimizationLevel = (
    state: BulkResizeState,
    { payload: optimizationLevel }: Payload
) => {
    state.exportSettings.optimizationLevel = optimizationLevel;
};
