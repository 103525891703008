import { setHeight } from "./crop/setHeight";
import { setRatio } from "./crop/setRatio";
import { setWidth } from "./crop/setWidth";
import { toggleCropOrientation } from "./crop/toggleCropOrientation";
import { setTop } from "./crop/setTop";
import { setLeft } from "./crop/setLeft";
import { setCustomRatioX } from "./crop/setCustomRatioX";
import { setCustomRatioY } from "./crop/setCustomRatioY";
import { setImage } from "./image/setImage";
import { setUploadData } from "./setUploadData";

export const reducers = {
    setHeight,
    setRatio,
    setWidth,
    setTop,
    setLeft,
    toggleCropOrientation,
    setImage,
    setUploadData,
    setCustomRatioX,
    setCustomRatioY
};
