import type { ReactNode } from "react";
import { Provider } from "react-redux";
import { store } from "store/store";
import { ToastsRenderer } from "../Toast/ToastRenderer";

export interface ReduxProviderProps {
    children: ReactNode;
}

export const ReduxProvider = ({ children }: ReduxProviderProps) => {
    return (
        <Provider store={store}>
            {children}
            <ToastsRenderer />
        </Provider>
    );
};
