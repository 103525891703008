import { BulkResizeState } from "../../types";

interface IPayload {
    payload: string;
}

export const deleteImage = (state: BulkResizeState, { payload: imageId }: IPayload) => {
    state.images = state.images.filter((image) => {
        return image.id !== imageId;
    });

    state.immutableImages = state.immutableImages.filter((image) => {
        return image.id !== imageId;
    });
};
