import { getSortedImages } from "store/utils/getSortedImages";
import { BulkResizeImage, BulkResizeState } from "../../types";

interface IPayload {
    payload: BulkResizeImage;
}

export const addImage = (state: BulkResizeState, { payload: _image }: IPayload) => {
    state.images.unshift(_image);
    state.images = getSortedImages<BulkResizeImage>(state.sort, state.images);
};
