import { setDownloadUrl } from "./setDownloadUrl";
import { setIsProcessing } from "./setIsProcessing";
import { setIsExporting } from "./setIsExporting";
import { reset } from "./reset";
import { setJobs } from "./setJobs";
import { appendJob } from "./appendJob";
import { editJob } from "./editJob";

export const reducers = {
    setDownloadUrl,
    setIsProcessing,
    setIsExporting,
    reset,
    setJobs,
    appendJob,
    editJob,
};
