import { FlipRotateState } from "../../types";

interface IPayload {
    payload: number;
}

export const setStraighten = (
    state: FlipRotateState,
    { payload: straighten }: IPayload
) => {
    state.rotate.straighten = straighten;
};
