import { addImage } from "./image/addImage";
import { setSelectedImage } from "./image/setSelectedImage";
import { deleteImage } from "./image/deleteImage";
import { deleteAllImages } from "./image/deleteAllImages";
import { setPreviewSize } from "./image/setPreviewSize";
import { setQuality } from "./quality/setQuality";
import { setSort } from "./sort/setSort";
import { setSliderValue } from "./image/setSliderValue";
import { setImages } from "./image/setImages";
import { setHasExpired } from "./image/setHasExpired";
import { setMaxFileSize as imageCompressionSettingsSetMaxFileSize } from "./image/compressionSettings/setMaxFileSize";
import { setQuality as imageCompressionSettingsSetQuality } from "./image/compressionSettings/setQuality";
import { setType as imageCompressionSettingsSetType } from "./image/compressionSettings/setType";
import { setAllImagesCompressionSettings } from "./compressionSettings/setAllImagesCompressionSettings";
import { setCompressed } from "./state/setCompressed";
import { setState } from "./state/setState";
import { complete as importComplete } from "./import/complete";
import { setImportTask } from "./import/setImportTask";
import { setProgress } from "./import/setProgress";
import { setError } from "./error/setError";
import { setTooltipBounceImageId } from "./tooltip/setTooltipBounceImageId";
import { setMaxFileSize as settingsSetMaxFileSize } from "./compressionSettings/setMaxFileSize";
import { setQuality as settingsSetQuality } from "./compressionSettings/setQuality";
import { setType as settingsSetType } from "./compressionSettings/setType";
import { setDownloadButtonState } from "./setDownloadButtonState";

export const reducers = {
    addImage,
    setSelectedImage,
    deleteImage,
    deleteAllImages,
    setPreviewSize,
    setQuality,
    setSliderValue,
    setSort,
    setImages,
    setHasExpired,
    imageCompressionSettingsSetMaxFileSize,
    imageCompressionSettingsSetQuality,
    imageCompressionSettingsSetType,
    setAllImagesCompressionSettings,
    setCompressed,
    setState,
    importComplete,
    setImportTask,
    setProgress,
    setError,
    setTooltipBounceImageId,
    settingsSetMaxFileSize,
    settingsSetQuality,
    settingsSetType,
    setDownloadButtonState
};
