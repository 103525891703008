import { CompressState } from "./types";
import { INITIAL_COMPRESS_QUALITY } from "./utils";

export const initialState: CompressState = {
    images: [],
    selectedImageId: null,
    sort: null,
    tooltipBounceImageId: null,
    fromDownloadPage: false,
    compressionSettings: {
        type: "quality",
        maxFileSize: {
            value: 0
        },
        quality: {
            value: INITIAL_COMPRESS_QUALITY
        }
    },
    downloadButtonState: {
        state: "active",
        progress: 0
    },
};
