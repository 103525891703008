import { FlipRotateState } from "./types";

export const initialState: FlipRotateState = {
    flip: {
        horizontal: false,
        vertical: false,
    },
    rotate: {
        rotation: 0,
        straighten: 0,
    },
    image: {
        src: "/images/sample-flip-and-rotate.png",
        title: "sample-image.png",
        height: 300,
        width: 300,
        initialHeight: 300,
        initialWidth: 300,
        originalHeight: 300,
        originalWidth: 300,
        containerWidth: 0,
        containerHeight: 0,
        ratio: 1,
        ext: "png",
    },
    isLoading: false,
    currentType: "flip",
    upload: {
        type: "url",
        settings: {
            url: "/images/sample-flip-and-rotate.png",
        },
    },
};
