export interface ICircularTickIconProps {
    stroke?: string;
}

export const CircularTickIcon = ({ stroke = "#27AE60" }: ICircularTickIconProps) => {
    return (
        <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.55469 11.0005L9.88802 14.3339L15.4436 8.77832"
                stroke={stroke}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 11C1 9.68678 1.25866 8.38642 1.7612 7.17317C2.26375 5.95991 3.00035 4.85752 3.92893 3.92893C4.85752 3.00035 5.95991 2.26375 7.17317 1.7612C8.38642 1.25866 9.68678 1 11 1C12.3132 1 13.6136 1.25866 14.8268 1.7612C16.0401 2.26375 17.1425 3.00035 18.0711 3.92893C18.9997 4.85752 19.7363 5.95991 20.2388 7.17317C20.7413 8.38642 21 9.68678 21 11C21 13.6522 19.9464 16.1957 18.0711 18.0711C16.1957 19.9464 13.6522 21 11 21C8.34784 21 5.8043 19.9464 3.92893 18.0711C2.05357 16.1957 1 13.6522 1 11V11Z"
                stroke={stroke}
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
};
