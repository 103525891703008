import { ResizeDownloadState } from "./types/types";

export const initialState: ResizeDownloadState = {
    image: {
        original: {
            width: 0,
            height: 0,
            size: "0 KB",
        },
        preview: {
            width: 0,
            height: 0,
            size: "0 KB",
        },
        src: "",
        title: "",
        format: ".jpg",
        createdAt: new Date(),
    },
};
