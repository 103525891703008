import { DPI } from "store/types/dpi";
import { BulkResizeState } from "../../types";

interface IPayload {
    payload: DPI;
}

export const setDPI = (
    state: BulkResizeState,
    { payload: dpi }: IPayload
) => {
    state.resizeSettings.dpi = dpi;
};
