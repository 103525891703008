import { ResizeDownloadState } from "../types/types";

interface IPayload {
    payload: {
        src: string;
    };
}

export const setSrc = (state: ResizeDownloadState, { payload: { src } }: IPayload) => {
    state.image.src = src;
};
