import { EnlargeState } from "../../types";

interface IPayload {
    payload: number;
}

export const setHeight = (state: EnlargeState, { payload: _height }: IPayload) => {
    let height = _height;
    if (!height || height < 0) {
        height = 0;
    }

    state.resizeSettings.byDimension.height = height;

    state.resizeSettings.byDimension.lastModified = "height";
    state.lastModified = "height";

    const isLocked = state.resizeSettings.isLocked;

    const ratio = state.image.original.width / state.image.original.height;
    const width = isLocked ? Math.floor(ratio * height) : state.resizeSettings.byDimension.width;

    state.image = {
        ...state.image,
        preview: {
            ...state.image.preview,
            width,
            height,
        },
    };

    state.resizeSettings = {
        ...state.resizeSettings,
        byDimension: {
            ...state.resizeSettings.byDimension,
            width,
            height,
            lastModified: "height",
        },
    };
};
