import { ResizeBackgroundImportState } from "../types";

interface Payload {
    payload: number;
}

export const setProgress = (state: ResizeBackgroundImportState, { payload: value }: Payload) => {
    if (typeof state.progress !== "undefined" && value < state.progress) return;
    state.progress = value;
};
