import type { ColorPickerState } from "./types";

export const initialState: ColorPickerState = {
    source: "color",
    image: {
        createdAt: new Date(),
        format: ".jpg",
        original: {
            height: 3800,
            width: 2400,
            size: "10.89 MB",
        },
        preview: {
            height: 3800,
            width: 2400,
            size: "10.89 MB",
        },
        src: "/images/color-picker-default-image.webp",
        title: "3D Abstract Sample Image Title.JPG",
    },
    fromImage: {
        colors: [],
    },
    selectedColor: null,
    currentColor: null,
    isImageLoading: true,
    upload: {
        type: "device",
        settings: {},
    },
};
