import { MemeGeneratorState } from "./types";

export const initialState: MemeGeneratorState = {
    meme: null,
    googleFonts: {
        list: [],
        isFetching: false,
    },
    recentlyUsedFonts: {
        list: [],
        isLoading: false,
    },
};
