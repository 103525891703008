import { Task } from "@freeconvert/freeconvert-node/dist/types";
import { CompressState } from "../../types";

interface IPayload {
    payload: {
        imageId: string;
        importTask: Task;
    };
}

export const setImportTask = (state: CompressState, { payload: { imageId, importTask } }: IPayload) => {
    state.images = state.images.map((_image) => {
        if (_image.id !== imageId) return _image;

        return {
            ..._image,
            importTask,
        };
    });
};
