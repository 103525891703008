import { CanvasImage } from "store/types/crop";
import { FlipRotateState } from "../../types";

interface IPayload {
    payload: CanvasImage;
}

export const setImage = (
    state: FlipRotateState,
    { payload: image }: IPayload
) => {
    state.image = image;
};
