import { EnlargeState } from "../../types";

interface IPayload {
    payload: number;
}

export const setPercentage = (
    state: EnlargeState,
    { payload: percentage }: IPayload
) => {
    if (!percentage || percentage < 0 || percentage > 200) return;

    state.resizeSettings.byPercentage.percentage = percentage;
    state.lastModified = "percentage";

    const width = Math.floor((state.image.original.width * percentage) / 100);
    const height = Math.floor((state.image.original.height * percentage) / 100);

    state.image = {
        ...state.image,
        preview: {
            ...state.image.preview,
            width,
            height,
        },
    };
};
