import { ToastsState, Toast } from "../types";

interface Payload {
    payload: {
        batch: string;
    };
}

export const removeBatch = (state: ToastsState, { payload: { batch } }: Payload) => {
    if (!batch) return;

    state.toasts = state.toasts.filter((toast) => toast.batch !== batch);
};
