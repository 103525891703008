import { CompressState } from "../../types";

interface IPayload {
    payload: number;
}

export const setQuality = (
    state: CompressState,
    { payload }: IPayload
) => {
    if (payload < 5 || payload > 100 || !state.selectedImageId) return;

    state.images = state.images.map((image) => {
        if (image.id !== state.selectedImageId) return { ...image };

        return {
            ...image,
            quality: payload,
        };
    });
};
