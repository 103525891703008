import { Size } from "store/types/previewImage";
import { CompressState } from "../../types";

interface IPayload {
    payload: Size;
}

export const setPreviewSize = (
    state: CompressState,
    { payload }: IPayload
) => {
    state.images = state.images.map((_image) => {
        if (_image.id !== state.selectedImageId) return _image;

        return {
            ..._image,
            preview: {
                ..._image.preview,
                size: payload,
            },
        };
    });
};
