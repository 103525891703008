import { CanvasImage } from "store/types/crop";
import { ResizeEditorState } from "../../types";

interface IPayload {
    payload: NonNullable<ResizeEditorState["image"]>;
}

export const setImage = (
    state: ResizeEditorState,
    { payload: image }: IPayload
) => {
    state.image = image;
};
