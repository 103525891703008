import { CompressState } from "../../types";

interface Payload {
    payload: {
        value: boolean;
        imageId: string;
    };
}

export const setHasExpired = (state: CompressState, { payload: { imageId, value } }: Payload) => {
    state.images = state.images.map((_image) => {
        if (_image.id !== imageId) return _image;
        _image.hasExpired = value;
        return _image;
    });
};
