import { AxiosError, AxiosRequestHeaders } from "axios";
import axiosInstance from "client/lib/axiosInterceptor";

export interface IPostReturn<T> {
    result: T | null;
    error: IError | null;
}

interface IError {
    message: string;
    code?: string;
}

export const post = async <T>(url: string, data: any, headers?: AxiosRequestHeaders): Promise<IPostReturn<T>> => {
    let result: T | null = null;
    let error: {
        message: string;
        code?: string;
    } | null = null;

    try {
        result = (
            await axiosInstance.post<T>(url, data, {
                headers: { ...(headers ?? {}) },
            })
        ).data;
    } catch (e) {
        const _error = e as AxiosError;
        error = {
            message: _error.message,
            code: _error.code || `${_error.response?.status}`,
        };
    }

    return { result, error };
};
