import tinycolor from "tinycolor2";
import { MemeTemplateTextBox } from "types/meme";
import { DEFAULT_FONT_SIZE } from "../data/configs";
import { TextBox } from "../types";

export const getTextBoxDefaultStyles = (
    textBox: Pick<MemeTemplateTextBox, "textAlign" | "verticalAlign" | "font" | "fontColor" | "forceCaps">,
): Pick<
    TextBox,
    "decoration" | "alignment" | "spacing" | "outline" | "shadow" | "opacity" | "fontFamily" | "fontColor" | "fontSize"
> => {
    const isDark = tinycolor(textBox.fontColor).isDark();
    const outlineColor = isDark ? "white" : "black";
    const shadowColor = isDark ? "white" : "black";
    return {
        decoration: {
            underline: false,
            bold: false,
            italic: false,
        },
        alignment: {
            horizontal: textBox.textAlign === "center" ? "middle" : "start",
            vertical: textBox.verticalAlign === "middle" ? "middle" : "start",
        },
        spacing: {
            letter: 1,
            line: 1.5,
        },
        outline: {
            size: 3,
            color: outlineColor,
            isActive: true,
        },
        shadow: {
            isActive: true,
            color: shadowColor,
            blurRadius: 5,
        },
        opacity: 100,
        fontFamily: textBox.font,
        fontColor: textBox.fontColor,
        fontSize: DEFAULT_FONT_SIZE,
    };
};
