import { ImageExtension } from "store/types/imageExtension";
import { BulkResizeState } from "../../types";

interface Payload {
    payload: ImageExtension | "original";
}

export const setSaveAs = (
    state: BulkResizeState,
    { payload }: Payload
) => {
    state.exportSettings.saveAs = payload;

    if (payload === ".jpg") {
        state.resizeSettings.fillColor.type === "color";
    }
};
