import axios from "axios";
import { GoogleFont } from "../types/googleFont";

export interface FontFetchResponseData {
    kind: string;
    items: GoogleFont[];
}

export async function fetchFonts() {
    return await axios.get<FontFetchResponseData>(
        `https://webfonts.googleapis.com/v1/webfonts?key=${process.env["NEXT_PUBLIC_GOOGLE_FONTS_API_KEY"]}&sort=popularity`,
    );
}
