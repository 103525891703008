import { sizeToBytes } from "client/utils/sizeToBytes";
import { PreviewImage } from "store/types/previewImage";
import { Sort } from "store/types/sort";

export const getSortedImages = <T extends PreviewImage>(sort: Sort | null, images: T[]): T[] => {
    switch (sort) {
        case "name-a-z":
            return [...images].sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
        case "name-z-a":
            return [...images].sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1));
        case "biggest-first":
            return [...images].sort((a, b) => sizeToBytes(b.original.size) - sizeToBytes(a.original.size));
        case "smallest-first":
            return [...images].sort((a, b) => sizeToBytes(a.original.size) - sizeToBytes(b.original.size));
        default:
            return images;
    }
};
