import { ImageUploaderState } from "./types";

export const initialState: ImageUploaderState = {
    images: [],
    uploadType: "device",
    invalidImageExtensionModal: {
        isOpen: false,
        allowedExts: [],
        invalidExts: [],
    },
};
