import { createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchFonts } from "../utils/fetchFonts";
import { GoogleFont } from "../types/googleFont";
import { MemeGeneratorState } from "../types";

export const fetchFontsThunk = createAsyncThunk("memeGenerator/fetchFontsThunk", async (): Promise<GoogleFont[]> => {
    try {
        const fontsData = await fetchFonts();
        return fontsData.data.items;
    } catch (e) {
        throw new Error((e as { message: string }).message);
    }
});

export const handleFetchFonts = (builder: ActionReducerMapBuilder<MemeGeneratorState>) => {
    builder.addCase(fetchFontsThunk.fulfilled, (state, { payload }) => {
        state.googleFonts = {
            list: payload.map((font) => {
                const fontFiles = font.files;
                Object.keys(fontFiles).map((key) => {
                    fontFiles[key] = fontFiles[key].replace("http", "https");
                });
                return { ...font, hasLoaded: false, files: fontFiles };
            }),
            isFetching: false,
        };
    });

    builder.addCase(fetchFontsThunk.pending, (state) => {
        state.googleFonts.isFetching = true;
    });

    builder.addCase(fetchFontsThunk.rejected, (state, request) => {
        state.googleFonts.isFetching = false;
    });
};
