import { ColorPickerState, Source } from "../types";

interface IPayload {
    payload: Source;
}

export const setSource = (
    state: ColorPickerState,
    { payload }: IPayload
) => {
    state.source = payload;
};
