import { FileUnit } from "store/features/bulkResize/types";
import { ResizeEditorState } from "../../types";
import { fileSizeLimit } from "./setTargetFileSize";

interface Payload {
    payload: FileUnit;
}

export const setFileUnit = (state: ResizeEditorState, { payload: fileUnit }: Payload) => {
    state.resize.exportSettings.fileUnit = fileUnit;

    if (state.resize.exportSettings.targetFileSize !== "" && state.resize.exportSettings.targetFileSize !== 0) {
        if (fileUnit === "MB" && state.resize.exportSettings.targetFileSize > fileSizeLimit / 1000) {
            state.resize.exportSettings.targetFileSize = fileSizeLimit / 1000;
        }
    }
};