import { MemeGeneratorState } from "../../types";

interface Payload {
    payload: string;
}

export const deleteTextBox = (state: MemeGeneratorState, { payload: id }: Payload) => {
    if (!state.meme) throw new Error("Meme not found");
    state.meme.textBoxes = state.meme.textBoxes.filter((_t) => _t.id !== id);
    if (state.meme.activeTextBoxId === id) state.meme.activeTextBoxId = undefined;
};
