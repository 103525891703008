import { CompressImageError, CompressState } from "../../types";

interface IPayload {
    payload: {
        imageId: string;
        error: CompressImageError;
    };
}

export const setError = (state: CompressState, { payload: { imageId, error } }: IPayload) => {
    state.images = state.images.map((_image) => {
        if (_image.id !== imageId) return _image;

        return {
            ..._image,
            error,
            state: "compression_failed"
        };
    });
};
