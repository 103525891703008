import { Size, Unit } from "store/types/previewImage";

export const sizeToBytes = (size: Size): number => {
    const value = Number(size.split(" ")[0]);
    const unit = size.split(" ")[1] as Unit;

    const marker = 1024;

    if (unit === "B") return value;

    if (unit === "KB") return value * marker;

    if (unit === "MB") return value * marker * marker;

    if (unit === "GB") return value * marker * marker * marker;

    return value;
};
