import { ResizeEditorState } from "../../types";

interface IPayload {
    payload: boolean;
}

export const setShouldFillColor = (
    state: ResizeEditorState,
    { payload: shouldFillColor }: IPayload
) => {
    state.resize.fillColor.shouldFillColor = shouldFillColor;
};
