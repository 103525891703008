import { ImageUploaderState, InvalidImageExtensionModal } from "../../types";

interface Payload {
    payload: Omit<InvalidImageExtensionModal, "isOpen">;
}

export const showModal = (state: ImageUploaderState, { payload: { allowedExts: validExts, invalidExts } }: Payload) => {
    state.invalidImageExtensionModal = {
        isOpen: true,
        allowedExts: validExts.filter(ext => ext !== "heif"),
        invalidExts,
    };
};
