import { convertFromPixels } from "client/utils/pixels/convertFromPixels";
import { convertToPixels } from "client/utils/pixels/convertToPixels";
import { Unit } from "store/types/unit";
import { ResizeEditorState } from "../../types";

interface IPayload {
    payload: Unit;
}

export const setUnit = (state: ResizeEditorState, { payload: unit }: IPayload) => {
    state.resize.byDimension.width = convertFromPixels(
        convertToPixels(state.resize.byDimension.width, state.resize.unit, +state.resize.dpi),
        unit,
        +state.resize.dpi,
    );
    state.resize.byDimension.height = convertFromPixels(
        convertToPixels(state.resize.byDimension.height, state.resize.unit, +state.resize.dpi),
        unit,
        +state.resize.dpi,
    );
    state.resize.unit = unit;
};
