import { Dimension } from "store/types/resizeSettings";
import { ResizeEditorState } from "../../types";

interface IPayload {
    payload: Dimension;
}

export const setDimensionType = (
    state: ResizeEditorState,
    { payload: dimension }: IPayload
) => {
    state.resize.dimensionType = dimension;
};
