import { getSortedImages } from "store/utils/getSortedImages";
import { CompressImage, CompressState } from "../../types";

interface IPayload {
    payload: CompressImage;
}

export const addImage = (state: CompressState, { payload }: IPayload) => {
    state.images.unshift(payload);
    state.images = getSortedImages<CompressImage>(state.sort, state.images);
};
