import { ImageUploaderState, UploadType } from "../types";

interface IPayload {
    payload: {
        uploadType: UploadType;
    };
}

export const setUploadType = (
    state: ImageUploaderState,
    { payload: { uploadType } }: IPayload
) => {
    state.uploadType = uploadType;
};
