import { CompressState } from "../../../types";

interface Payload {
    payload: {
        type: CompressState["compressionSettings"]["type"];
        imageId: string;
    };
}

export const setType = (state: CompressState, { payload: { imageId, type } }: Payload) => {
    state.images = state.images.map(image => {
        if (image.id !== imageId) return image;
        image.compressionSettings.type = type;
        return image;
    });
};