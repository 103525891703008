import { BulkResizeState } from "./types";

// TODO: should not require immutable images
export const initialState: BulkResizeState = {
    exportSettings: {
        optimizationLevel: "custom",
        saveAs: "original",
        maxFileSize: 0,
        fileUnit: "KB",
    },
    resizeSettings: {
        byDimension: {
            height: 0,
            width: 0,
            lastModified: "width",
        },
        byPercentage: {
            percentage: 100,
        },
        dpi: "300",
        unit: "pixels",
        dimensionType: "pixels",
        fillColor: {
            color: "#000000",
            shouldFillColor: true,
            type: "color"
        },
        isLocked: true,
    },
    sort: null,
    images: [],
    immutableImages: [],
    lastModified: null,
    fromDownloadPage: false,
};
