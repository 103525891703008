import { CompressImage, CompressState } from "../../types";

interface Payload {
    payload: {
        imageId: string;
        imageState: CompressImage["state"];
    };
}

export const setState = (state: CompressState, { payload: { imageId, imageState } }: Payload) => {
    state.images = state.images.map(image => {
        if (image.id !== imageId) return image;
        image.state = imageState;
        return image;
    });
};