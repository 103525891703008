import { MemeGeneratorState } from "../../types";

interface Payload {
    payload: MemeGeneratorState["recentlyUsedFonts"]["list"][number];
}

export const appendToRecentlyUsedFonts = (state: MemeGeneratorState, { payload }: Payload) => {
    state.recentlyUsedFonts.list = [
        payload,
        ...state.recentlyUsedFonts.list.filter((_f) => _f.family !== payload.family).slice(0, 4),
    ];
};
