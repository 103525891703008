import { BulkResizeImage, BulkResizeState } from "../../types";
import { current } from "@reduxjs/toolkit";

interface Payload {
    payload: {
        imageId: string;
        width: number;
        height: number;
    };
}

export const setDimension = (state: BulkResizeState, { payload: { imageId, width, height } }: Payload) => {
    state.images = [
        ...state.images.map((_image) => {
            if (_image.id !== imageId) return _image;
            const previewWidth = getPreviewWidth(state, width, height);
            const previewHeight = getPreviewHeight(state, width, height);

            return {
                ..._image,
                original: {
                    ..._image.original,
                    width,
                    height,
                },
                preview: {
                    ..._image.preview,
                    width: previewWidth,
                    height: previewHeight,
                },
            };
        }),
    ];
};

const getPreviewWidth = (state: BulkResizeState, originalWidth: number, originalHeight: number): number => {
    if (state.resizeSettings.dimensionType === "percentage") {
        return (state.resizeSettings.byPercentage.percentage * originalWidth) / 100;
    }

    if (state.resizeSettings.isLocked) {
        if (state.resizeSettings.byDimension.lastModified === "height" && state.resizeSettings.byDimension.height > 0) {
            return (originalWidth / originalHeight) * state.resizeSettings.byDimension.height;
        }

        if (state.resizeSettings.byDimension.width > 0) {
            return state.resizeSettings.byDimension.width;
        }
    }

    return originalWidth;
};

const getPreviewHeight = (state: BulkResizeState, originalWidth: number, originalHeight: number): number => {
    if (state.resizeSettings.dimensionType === "percentage") {
        return (state.resizeSettings.byPercentage.percentage * originalHeight) / 100;
    }

    if (state.resizeSettings.isLocked) {
        if (state.resizeSettings.byDimension.lastModified === "width" && state.resizeSettings.byDimension.width > 0) {
            return (originalHeight / originalWidth) * state.resizeSettings.byDimension.width;
        }

        if (state.resizeSettings.byDimension.height > 0) {
            return state.resizeSettings.byDimension.height;
        }
    }

    return originalHeight;
};
