import { MemeGeneratorState } from "../../types";

interface Payload {
    payload: string;
}

export const removeIcon = (state: MemeGeneratorState, { payload }: Payload) => {
    if (!state.meme) throw new Error(`Tried to add icon when meme is ${state.meme}`);
    state.meme.icons = state.meme.icons.filter((icon) => icon.id !== payload);
};
