import { Meme, MemeGeneratorState } from "../../types";

interface Payload {
    payload: Meme["activeIconId"];
}

export const setActiveIconId = (state: MemeGeneratorState, { payload }: Payload) => {
    if (!state.meme) return;
    state.meme.activeIconId = payload;
    if (typeof payload !== "undefined") state.meme.activeTextBoxId = undefined;
};
