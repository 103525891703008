import { CropRatioType } from "store/types/crop";
import { ResizeEditorState } from "../../types";

interface IPayload {
    payload: CropRatioType;
}

export const setRatio = (
    state: ResizeEditorState,
    { payload: ratio }: IPayload
) => {
    state.crop.ratio = ratio;
};
