import { OptimizationLevel } from "store/features/bulkResize/types";
import { ResizeEditorState } from "../../types";

interface Payload {
    payload: OptimizationLevel;
}

export const setOptimizationLevel = (state: ResizeEditorState, { payload: optimizationLevel }: Payload) => {
    state.resize.exportSettings.optimizationLevel = optimizationLevel;
};
