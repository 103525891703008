import { Toast } from "store/features/toasts/types";
import { CircularTickIcon } from "../Icons/CircularTickIcon";

export interface IToastComponentProps {
    toast: Toast;
}

export const ToastComponent = ({ toast }: IToastComponentProps) => {
    return (
        <div
            className={`font-semibold py-2 px-4 flex items-center gap-4 ${
                toast.status === "success"
                    ? "bg-success"
                    : toast.status === "error"
                    ? "bg-error"
                    : toast.status === "information"
                    ? "bg-information"
                    : toast.status === "warning"
                    ? "bg-warning"
                    : "bg-warning"
            } text-white text-xl rounded-md my-1 animate-scale-in`}
        >
            {toast.status === "success" && (
                <div className="">
                    <CircularTickIcon stroke="white" />
                </div>
            )}
            <div className="" id="toast-text">
                {toast.text}
            </div>
        </div>
    );
};
