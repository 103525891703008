import { RotateSettings } from "store/types/rotate";
import { ResizeEditorState } from "../../types";

interface Payload {
    payload: { value: RotateSettings["initialData"] };
}

export const setInitialData = (state: ResizeEditorState, { payload: { value } }: Payload) => {
    state.rotate.initialData = value;
};
