import { EnlargeState } from "../../types";

interface IPayload {
    payload: boolean;
}

export const setShouldFillColor = (
    state: EnlargeState,
    { payload: shouldFillColor }: IPayload
) => {
    state.resizeSettings.fillColor.shouldFillColor = shouldFillColor;
};
