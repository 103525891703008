import { FlipRotateState } from "../../types";

interface IPayload {
    payload: number;
}

export const setRotation = (
    state: FlipRotateState,
    { payload: rotation }: IPayload
) => {
    state.rotate.rotation = rotation;
};
