import { CropState } from "../../types";

interface IPayload {
    payload: number;
}

export const setTop = (state: CropState, { payload: value }: IPayload) => {
    if (value < 0 || !state.image || value > state.image.originalHeight) return;

    state.crop.top = value;
};
