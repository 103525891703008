import { MemeGeneratorState, MemeIconState, TextBoxStates } from "../../types";

interface Payload {
    payload: NonNullable<MemeGeneratorState["meme"]>["activeTextBoxId"];
}

export const setActiveTextBoxId = (state: MemeGeneratorState, { payload }: Payload) => {
    if (!state.meme) return;

    // handle active icon
    if (typeof payload !== "undefined" && typeof state.meme.activeIconId !== "undefined") {
        const icon = state.meme.icons.find((_i) => _i.id === state.meme?.activeIconId);
        if (icon) {
            icon.state = MemeIconState.INACTIVE;
            icon.showBorder = false;
        }
        state.meme.activeIconId = undefined;
    }

    // handle active textbox
    if (
        typeof payload !== "undefined" &&
        typeof state.meme.activeTextBoxId !== "undefined" &&
        payload !== state.meme.activeTextBoxId
    ) {
        const textBox = state.meme.textBoxes.find((_t) => _t.id === state.meme?.activeTextBoxId);
        if (!textBox) return;
        textBox.state = TextBoxStates.BLUR;
        textBox.showBorder = false;
        textBox.showHandlers = false;
    }

    state.meme.activeTextBoxId = payload;
};
