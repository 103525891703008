import { ResizeEditorState } from "../../types";

interface IPayload {
    payload: boolean;
}

export const setIsLocked = (state: ResizeEditorState, { payload: isLocked }: IPayload) => {
    state.resize.isLocked = isLocked;
    const cropBox = state.crop.cropBox;
    if (isLocked && cropBox) {
        if (state.resize.byDimension.lastModified === "width") {
            state.resize.byDimension.height = Math.round(
                state.resize.byDimension.width * (cropBox.height / cropBox.width),
            );
        } else {
            state.resize.byDimension.width = Math.round(state.resize.byDimension.height * (cropBox.width / cropBox.height));
        }
    }
};
