import { getRatioValue } from "client/utils/getRatioValue";
import { CropState } from "../../types";

interface IPayload {
    payload: number;
}

export const setWidth = (state: CropState, { payload: _width }: IPayload) => {
    if (!state.image) return;
    state.crop.width = _width > 0 ? (_width > state.image.originalWidth ? state.image.originalWidth : _width) : 0;
};
